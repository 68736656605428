import React from "react";
import "./StepBar.css";

export default function StepBar({ num, selectedTheme }) {
  const handleSkipClick = () => {
    const confirmed = window.confirm(
      "Are you sure? Your changes will not be saved."
    );
    if (confirmed) {
      window.location.href = "/profile";
    } else {
      // Stay on the current page
      console.log("Stay on the current page...");
    }
  };

  return (
    <div className="progress-header">
      <div className="ul-progress">
        <div
          className={`ul-progress-box ${
            selectedTheme === "t1"
              ? "ulb-t1"
              : selectedTheme === "t3"
              ? "ulb-t3"
              : "ulb-t2"
          }`}
        >
          <div className="ul-box1"></div>
          <div className="ul-box2"></div>
          <div className="ul-box3"></div>
          <div className="ul-box4"></div>
        </div>

        <button className="ul-skip" onClick={handleSkipClick}>
          {" "}
          Skip{" "}
        </button>
      </div>
      <div className="ul-progress-num">
        <h> {num}/4</h>
      </div>
    </div>
  );
}
