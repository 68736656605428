import React, { useState, useRef, useEffect } from "react";
import { SlOptions } from "react-icons/sl";
import { IoSettingsOutline } from "react-icons/io5";
import Settings from "../Settings/Settings";
import "./HomeOptions.css";

export default function HomeOptions() {
  const [modalOptions, setModalOptions] = useState(false);
  const [modalSettings, setModalSettings] = useState(false);
  const modalOptionsRef = useRef(null);
  const modalSettingsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalOptionsRef.current &&
        !modalOptionsRef.current.contains(event.target)
      ) {
        setModalOptions(false);
      }
      if (
        modalSettingsRef.current &&
        !modalSettingsRef.current.contains(event.target)
      ) {
        setModalSettings(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        className="profile-options-btn"
        onClick={() => {
          setModalOptions(true);
        }}
      >
        <SlOptions className="profile-options-icon" />
      </div>

      {modalOptions && (
        <div className="home-options-popup-m" ref={modalOptionsRef}>
          <div
            className="hop-settings-sec"
            onClick={() => {
              setModalSettings(true);
              setModalOptions(false);
            }}
          >
            <div className="hop-settings-label">Settings</div>
            <IoSettingsOutline />
            <div className="hop-settings-icon"></div>
          </div>
        </div>
      )}

      {modalSettings && (
        <div ref={modalSettingsRef}>
          <Settings closeModalSettings={() => setModalSettings(false)} />
        </div>
      )}
    </div>
  );
}
