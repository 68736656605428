import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import Popup from "reactjs-popup";
import Form from "react-bootstrap/Form";
import Cookies from "js-cookie";
import CloseButton from "react-bootstrap/CloseButton";
import Designation from "../../Assets/Designation.svg";
import API_DOMAIN from "../../ApiConfig";
import "./UserDesignation.css";

export default function UserDesignation({ id }) {
  const [position, setPosition] = useState("");
  const [isPosted, setIsPosted] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  id = Cookies.get("id");

  //fetch designation
  useEffect(() => {
    const fetchDesignation = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/designation/view?id=${id}`);
        console.log(response);
        if (!response.ok) {
          throw new Error("Failed to fetch designation");
        }
        const token = await response.json();

        if (token.success) {
          console.log(token);
          const { position } = jwtDecode(token.token);
          console.log(position);
          setPosition(position);
          setIsPosted(true);
        }
      } catch (error) {
        console.error("Error fetching designation:", error);
      }
    };
    fetchDesignation();
  }, [id]);

  //Post designation to API
  const postDesignation = async () => {
    try {
      await fetch(`${API_DOMAIN}/designation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, position }),
      });
      console.log("Designation is successfully added to API");
      console.log({ id, position });
      setIsPosted(true);
      setTimeout(() => {
        setSuccessMsg(true);
      }, 5000);
      setSuccessMsg("Designation is added successfully");
      window.location.href = "/profile";
    } catch (error) {
      console.error("Error in sending designation:", error);
    }
  };

  //Update designation
  const updateDesignation = async () => {
    try {
      await fetch(`${API_DOMAIN}/designation/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, position }),
      });
      console.log("Designation is updated successfully:", position);
      setTimeout(() => {
        setSuccessMsg(true);
      }, 5000);
      setSuccessMsg("Designation is updated successfully");
      window.location.href = "/profile";
    } catch (error) {
      console.error("Error in updating designation:", error);
    }
  };

  //Delete designation
  const deleteDesignation = async () => {
    try {
      await fetch(`${API_DOMAIN}/designation/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      console.log("Designation is deleted successfully");
      setIsPosted(false);
      setTimeout(() => {
        setSuccessMsg(true);
      }, 5000);
      setSuccessMsg("Designation is deleted successfully");
      setPosition("");
      window.location.href = "/profile";
    } catch (error) {
      console.error("Error in deleting designation:", error);
    }
  };

  return (
    <Popup
      trigger={
        <button className="demographic">
          <img src={Designation} alt="" className="demo-icon" />

          <span className="demo-title">
            {isPosted
              ? `${position.slice(0, 20)}${position.length > 20 ? "..." : ""}`
              : "Designation"}
          </span>
        </button>
      }
      modal
      nested
    >
      {(close) => (
        <div className="designation-popup">
          <div className="designation-popup-header">
            <div className="designation-box">
              <div className="designation-close">
                <button
                  className="designation-close-btn"
                  onClick={() => close()}
                >
                  <CloseButton style={{ height: "5px", width: "5px" }} />
                </button>
              </div>
              <div className="designation-title">
                <div>Designation</div>
              </div>
            </div>
          </div>
          <div className="designation-body">
            <div className="type-designation">
              <Form.Control
                type="text"
                placeholder="Designation"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
            </div>
          </div>
          <div className="designation-footer">
            {!isPosted ? (
              <button className="designation-add" onClick={postDesignation}>
                Add
              </button>
            ) : (
              <div className="designation-update-delete">
                <button
                  className="designation-update"
                  onClick={updateDesignation}
                >
                  Update
                </button>
                <button
                  className="designation-delete"
                  onClick={deleteDesignation}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
          {successMsg && <div className="success-message">{successMsg}</div>}
        </div>
      )}
    </Popup>
  );
}
