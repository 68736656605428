import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import CloseButton from "react-bootstrap/CloseButton";
import { BsThreeDots } from "react-icons/bs";
// import { MdContentCopy } from "react-icons/md";
import { BsYoutube, BsInstagram, BsSnapchat } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";
import {
  BiLogoFacebook,
  BiLogoTiktok,
  BiLogoPatreon,
  BiLogoLinkedin,
} from "react-icons/bi";
import { FaPinterestP, FaTumblr } from "react-icons/fa";
import { SlSocialVkontakte } from "react-icons/sl";
import "./SMOptions.css";
import API_DOMAIN from "../../ApiConfig";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

export default function SMOptions({ type }) {
  const [updatedUserName, setUpdatedUsername] = useState("")
  const id = Cookies.get("id")
  
  const deleteSMLinks = async () => {
    try {
      await fetch(`${API_DOMAIN}/socialmedia/delete/${id}/${type}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      });
      console.log("Successfully deleted social media links");
      window.location.href = "/profile";
    } catch (error) {
      console.error("Failed to delete social media links:", error);
    }
  };

  //post Social Media Links
  const updateSMLinks = async () => {
    try {
      const updateSocial = [{type, username: updatedUserName}]
      await fetch(`${API_DOMAIN}/socialmedia/update/${id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          updates: updateSocial,
        }),
      });

      console.log(
        "Social Media Link is updated successfully: ",
        updateSocial
      );
      console.log(updateSocial);
      // window.location.href = "/profile";
    } catch (error) {
      console.error("Failed to update custom links:", error);
    }
  };

  //fetch Social Media Links
  useEffect(() => {
    const fetchSMLinks = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/socialmedia/view/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch social media links");
        }
        const token = await response.json();
        if (token.success) {
          console.log(token);
          const { socialMediaLinks } = jwtDecode(token.token);
          console.log("Social Media Links:", socialMediaLinks);

          const filteredLinks = socialMediaLinks.find(
            (link) => link.type === type
          );
          if (filteredLinks) {
            setUpdatedUsername(filteredLinks.sm_username);
          }
        }
      } catch (error) {
        console.error("Failed to fetch social media usernames:", error);
      }
    };
    fetchSMLinks();
  }, [id,type]);

  const SMType = (type) => {
    switch (type) {
      case "TikTok":
        return <BiLogoTiktok />;
      case "Facebook":
        return <BiLogoFacebook />;
      case "X":
        return <BsTwitterX />;
      case "Instagram":
        return <BsInstagram />;
      case "Pinterest":
        return <FaPinterestP />;
      case "Patreon":
        return <BiLogoPatreon />;
      case "LinkedIn":
        return <BiLogoLinkedin />;
      case "Tumblr":
        return <FaTumblr />;
      case "VK":
        return <SlSocialVkontakte />;
      case "Snapchat":
        return <BsSnapchat />;
      case "Youtube":
        return <BsYoutube />;
      default:
        return null;
    }
  };

  return (
    <Popup
      trigger={
        <span className="options">
          <BsThreeDots />
        </span>
      }
      modal
      nested
    >
      {(close) => (
        <div className="options-popup">
          <div className="options-popup-header">
            <div className="options-title">{type}</div>
            <button className="options-close" onClick={() => close()}>
              <CloseButton style={{ height: "5px", width: "5px" }} />
            </button>
          </div>

          <div className="options-popup-body">
            <div className="options-label">Username or Page ID</div>
            <div className="link-area">
              <div className="shortly-icon">{SMType(type)}</div>
              <input
                type="text"
                className="link-text"
                placeholder="Username"
                value={updatedUserName}
                onChange={(e) => setUpdatedUsername(e.target.value)}
              />
              {/* <div className="link-copy">
                <MdContentCopy className="copy-icon" />
                <div className="copy">Copy</div>
              </div> */}
            </div>

            <div className="options-btn">
              <div className="update" onClick={updateSMLinks}>
                Update
              </div>
              <div className="remove" onClick={deleteSMLinks}>
                Remove
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}
