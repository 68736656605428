import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { RxCross2 } from "react-icons/rx";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineContentCopy } from "react-icons/md";
import { PiDownloadSimpleBold } from "react-icons/pi";
import QRCode from "react-qr-code";
import API_DOMAIN from "../../ApiConfig";
import Cookies from "js-cookie";
import "./ShareProfile.css";

export default function ShareProfile({ id, closeShareModal }) {
  const [userName, setUserName] = useState("");
  const [copiedText, setCopiedText] = useState("");

  id = Cookies.get("id");

  //copy to clipboard
  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText = `tinly.me/${userName}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopiedText("Copied");
  };

  //prevent close modal when click inside
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  //fetch username and first name
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/user?id=${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }
        const token = await response.json();
        console.log(token);
        const { userName } = jwtDecode(token.token);
        setUserName(userName);

        console.log(token);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };
    fetchData();
  });

  return (
    <div className="share-profile-popup" onClick={handleModalClick}>
      <div className="spp-header">
        <div className="spp-title">Sharing</div>
        <div
          className="spp-close"
          onClick={() => {
            closeShareModal(false);
          }}
        >
          <RxCross2 />
        </div>
      </div>

      <div className="spp-body">
        <div className="spp-body-icon">
          <IoSettingsOutline />
        </div>

        <div className="spp-copy-url">
          <input className="spp-url" value={`tinly.me/${userName}`} />
          <div className="spp-url-copyicon" onClick={copyToClipboard}>
            {copiedText ? (
              <div className="copied-text">Copied</div>
            ) : (
              <MdOutlineContentCopy />
            )}
          </div>
        </div>

        <div className="spp-body-qr">
          <QRCode value={`tinly.me/${userName}`} className="spp-body-qr-code" />
          <div className="download-qr">
            <div className="download-qr-icon">
              <PiDownloadSimpleBold/>
            </div>
          </div>
        </div>

        <div className="spp-share-msg">
          <div className="spp-share-own">
            {" "}
            <span contentEditable="true">
              Hey! 🚀 Join with my Tinly Free Profile, the coolest app in town!
              📲 Connect with my profile now: tinly.me/{userName}
              🔗 Let's share the fun! 🎉
            </span>{" "}
          </div>

          <div className="spp-share-other">
            {" "}
            <span contentEditable="true">
              Hey! 👋 Meet {userName} on Tinly! 🌐 Dive into their world of fun
              moments. 📲 Connect now: tinly.me/{userName} 🔗 Let's amplify the
              social joy! 🎊
            </span>{" "}
          </div>
        </div>
      </div>

      <div className="spp-footer">
        <div className="spp-share-btn">Share</div>
      </div>
    </div>
  );
}
