import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Cookies from "js-cookie";
import { AiFillHeart } from "react-icons/ai";
import TinlyLogo from "../../Assets/TinlyLogo.png";
import API_DOMAIN from "../../ApiConfig";
import "./GetStarted.css";

export default function GetStarted({ userName }) {
  Cookies.get("userName", userName);

  useEffect(() => {
    const fetchtoken = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/login/detail/${userName}`
        );
        console.log(response);
        if (!response.ok) {
          throw new Error("Failed to fetch token and id");
        }
        const data = await response.json();
        console.log(data);
        const token = data.token;
        const id = data.userid;

        Cookies.set("token", token);
        Cookies.set("id", id);
      } catch (error) {
        console.error("Failed to fetch token and id: ", error);
      }
    };
    fetchtoken();
  }, [userName]);

  return (
    <div className="gs-container">
      <div className="gs-wrapper">
        <div className="gs-header">
          <div className="gs-tinly-logo">
            <Image src={TinlyLogo} alt="" className="gs-logo" />
          </div>
          <div className="gs-title">
            <h> Tinly </h>
          </div>
        </div>
        <div className="gs-body">
          <div className="gs-body-box">
            <div className="gs-welcome">
              <h> Welcome Back!</h>
            </div>
            <div className="gs-note">
              <p>
                You're officially in. Let's make every click count! Start
                exploring now.
              </p>
            </div>
            <div className="gs-btn-box">
              <Link to="/uname">
                <button className="gs-btn"> Get Started </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="gs-footer">
        <p>
           Made &nbsp;<AiFillHeart className="heart-icon"/> &nbsp; with Tinly
          </p>
        </div>
      </div>
    </div>
  );
}
