import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { AiFillHeart } from "react-icons/ai";
import Select from "react-select";
import StepBar from "../../Components/StepBar/StepBar";
import "./SetDemo.css";

export default function SetDemo({ id }) {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  // const [selectedCountry, setSelectedCountry] = useState("");
  // const [selectedCity, setSelectedCity] = useState("");
  const [position, setPosition] = useState("");
  const [workPlace, setWorkPlace] = useState("");

  const fetchCountries = async () => {
    const country = await Axios.get(
      "https://countriesnow.space/api/v0.1/countries"
    );
    console.log(country);

    setCountries(country.data.data);
  };

  const fetchCities = (country) => {
    const city = countries.find((c) => c.country === country);
    setCities(city.cities);
  };

  // const handleCountry = (e) => {
  //   const country = e.target.value;
  //   setSelectedCountry(country);
  //   fetchCities(country);
  // };

  // const handleCity = (e) => {
  //   const city = e.target.value;
  //   setSelectedCity(city);
  // };

  // useEffect(() => {
  //   const savedBio = sessionStorage.getItem("bio");
  //   if (savedBio) {
  //     postBio(savedBio);
  //   }
  //   const savedCity = sessionStorage.setItem("city", city);
  //   const savedCountry = sessionStorage.setItem("country", country);
  //   if (savedCountry && savedCity) {
  //     postLocation({ savedCity }, { savedCountry });
  //   }
  //   const savedDesignation = sessionStorage.setItem("position", position);
  //   if (savedDesignation) {
  //     postDesignation(savedDesignation);
  //   }
  //   const savedWorkPlace = sessionStorage.setItem("work_place", work_place);
  //   if (savedWorkPlace) {
  //     postWorkPlace(savedWorkPlace);
  //   }
  // });

  //Post bio to API
  // const postBio = async (bio) => {
  //   try {
  //     await fetch(`${API_DOMAIN}/bio`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ id, bio }),
  //     });
  //     console.log("Bio Description is successfully added to API");
  //     console.log({ id, bio });
  //   } catch (error) {
  //     console.error("Error in posting bio description:", error);
  //   }
  // };

  useEffect(() => {
    const savedCountry = sessionStorage.getItem("country");
    const savedCity = sessionStorage.getItem("city");
    const savedDesignation = sessionStorage.getItem("position");
    const savedWorkPlace = sessionStorage.getItem("workPlace");

    if (savedCountry) setCountry(savedCountry);
    if (savedCity) setCity(savedCity);
    if (savedDesignation) setPosition(savedDesignation);
    if (savedWorkPlace) setWorkPlace(savedWorkPlace);

    window.addEventListener("beforeunload", clearSessionStorage);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorage);
    };
  }, []);

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleClick = () => {
    sessionStorage.setItem("country", country);
    sessionStorage.setItem("city", city);
    sessionStorage.setItem("position", position);
    sessionStorage.setItem("workPlace", workPlace);
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem("country");
    sessionStorage.removeItem("city");
    sessionStorage.removeItem("position");
    sessionStorage.removeItem("workPlace");
  };

  // //Post designation to API
  // const postDesignation = async () => {
  //   try {
  //     await fetch(`${API_DOMAIN}/designation`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ id, position }),
  //     });
  //     console.log("Designation is successfully added to API");
  //     console.log({ id, position });
  //   } catch (error) {
  //     console.error("Error in sending designation:", error);
  //   }
  // };

  // const postLocation = async () => {
  //   try {
  //     await fetch(`${API_DOMAIN}/location`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         country: selectedCountry,
  //         city: selectedCity,
  //         id,
  //       }),
  //     });
  //     console.log("Location is successfully added to API");
  //     console.log({ country: selectedCountry, city: selectedCity, id });
  //   } catch (error) {
  //     console.error("Error in sending designation:", error);
  //   }
  // };

  //post work place
  // const postWorkPlace = async () => {
  //   try {
  //     await fetch(`${API_DOMAIN}/Work`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ work_place, id }),
  //     });
  //     console.log("Work Place is added successfully:", { work_place, id });
  //   } catch (error) {
  //     console.error("Failed to post work place:", error);
  //   }
  // };

  // const handleSubmit =()=>{
  //     sessionStorage.setItem("country", selectedCountry);
  //     sessionStorage.setItem("city", selectedCity);
  //     sessionStorage.setItem("position", position);
  //     sessionStorage.setItem("work_place", work_place);

  //     postLocation();
  //     postDesignation();
  //     postWorkPlace();
  //     postBio();
  // }

  return (
    <div className="demo-container">
      <div className="demo-wrapper">
        <div className="demo-header">
          <StepBar num={3} />
        </div>
        <div className="demo-body">
          <div className="db-wrapper">
            <div className="db-topic">
              Let's craft your Tinly experience together.
            </div>
            <div className="db-sub-topic">
              <h>Add Your Demographic Details Here</h>
            </div>
            <div className="add-demo">
              <div className="location-sec">
                <span className="label">Your Country</span>
                <Select
                  className="demo-location"
                  options={countries.map((country) => ({
                    value: country.country,
                    label: country.country,
                  }))}
                  onChange={(selectedOption) => {
                    setCountry(selectedOption.value);
                    fetchCities(selectedOption.value);
                  }}
                  placeholder="Select Country"
                />
              </div>
              <div className="location-sec">
                <span className="label">Your City</span>
                <Select
                  className="demo-location"
                  options={cities.map((city) => ({ value: city, label: city }))}
                  onChange={(selectedOption) => setCity(selectedOption.value)}
                  placeholder="Select City"
                />
              </div>
              <div className="designation-sec">
                <span className="label">Your Designation</span>
                <input
                  type="text"
                  className="demo-text"
                  placeholder=""
                  maxLength={50}
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
              </div>
              <div className="workplace-sec">
                <span className="label">Your Work Place</span>
                <input
                  type="text"
                  className="demo-text"
                  placeholder=""
                  maxLength={50}
                  value={workPlace}
                  onChange={(e) => setWorkPlace(e.target.value)}
                />
              </div>
            </div>
            <div className="db-button">
              <Link to="/bio">
                <button className="btn-prev" onClick={handleClick}>
                  {" "}
                  &lt; Previous{" "}
                </button>
              </Link>
              <Link to="/theme">
                <button onClick={handleClick}> Next &gt; </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="db-footer">
          <p>
            Made &nbsp;
            <AiFillHeart className="heart-icon" /> &nbsp; with Tinly
          </p>
        </div>
      </div>
    </div>
  );
}
