import React from 'react'
import { AiFillHeart } from "react-icons/ai";
import './Footer.css'

export default function Footer() {
  return (
    <div className="footer">
        <p>
            Made &nbsp;
            <AiFillHeart className="heart-icon" /> &nbsp; with Tinly
          </p>
    </div>
  )
}
