import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import Form from "react-bootstrap/Form";
import ReactSwitch from "react-switch";
import { IoMdAdd } from "react-icons/io";
import CloseButton from "react-bootstrap/CloseButton";
import PhoneNumber from "../../Assets/Phone.svg";
import Email from "../../Assets/Mail.svg";
import Whatsapp from "../../Assets/whatsapp_white_1.svg";
import MailingAddress from "../../Assets/MailingAddress.svg";
import Telegram from "../../Assets/Telegram.svg";
import Viber from "../../Assets/Viber.svg";
import WeChat from "../../Assets/WeChat.svg";
import API_DOMAIN from "../../ApiConfig";
import "./PrivateLinks.css";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function PrivateLinks() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [telegram, setTelegram] = useState("");
  const [viber, setViber] = useState("");
  const [weChat, setWeChat] = useState("");
  const [phoneprivacyStatus, setPhonePrivacyStatus] = useState("");
  const [mailprivacyStatus, setMailPrivacyStatus] = useState("");
  const [emailprivacyStatus, setEmailPrivacyStatus] = useState("");
  const [whatsappprivacyStatus, setWhatsappPrivacyStatus] = useState("");
  const [telegramprivacyStatus, setTelegramPrivacyStatus] = useState("");
  const [viberprivacyStatus, setviberPrivacyStatus] = useState("");
  const [wechatprivacyStatus, setWechatPrivacyStatus] = useState("");
  const [phoneCountryCode, setPhoneCountryCode] = useState();
  const [whatsappCountryCode, setWhatsappCountryCode] = useState();
  const [telegramCountryCode, setTelegramCountryCode] = useState();
  const [viberCountryCode, setViberCountryCode] = useState();
  const [wechatCountryCode, setwechatCountryCode] = useState();
  const [, setPrivateLinkAdded] = useState(false);

  const id = Cookies.get("id");
  const [checkToggle, setCheckToggle] = useState({
    phone: false,
    email: false,
    address: false,
    whatsapp: false,
    telegram: false,
    viber: false,
    wechat: false,
  });

  //fetch private links
  useEffect(() => {
    const fetchprivateLinks = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/private/view/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch private links");
        }
        const token = await response.json();

        if (token.success) {
          const { privateLinks } = jwtDecode(token.token);
          console.log("Private Links:", privateLinks);

          privateLinks.forEach((plink) => {
            const { type, link, privacy_status } = plink;
            switch (type) {
              case "Phone number":
                setPhoneNumber(link);
                setPhonePrivacyStatus(privacy_status);
                handleToggle("phone", true);
                break;
              case "Mailing Address":
                setMailingAddress(link);
                setMailPrivacyStatus(privacy_status);
                handleToggle("address", true);
                break;
              case "Email Address":
                setEmail(link);
                setEmailPrivacyStatus(privacy_status);
                handleToggle("email", true);
                break;
              case "Whatsapp":
                setWhatsapp(link);
                setWhatsappPrivacyStatus(privacy_status);
                handleToggle("whatsapp", true);
                break;
              case "Telegram":
                setTelegram(link);
                setTelegramPrivacyStatus(privacy_status);
                handleToggle("telegram", true);
                break;
              case "Viber":
                setViber(link);
                setviberPrivacyStatus(privacy_status);
                handleToggle("viber", true);
                break;
              case "WeChat":
                setWeChat(link);
                setWechatPrivacyStatus(privacy_status);
                handleToggle("wechat", true);
                break;
              default:
                break;
            }
            setPrivateLinkAdded(true);
          });
        }
      } catch (error) {
        console.error("Failed to fetch private links:", error);
      }
    };
    fetchprivateLinks();
  }, [id]);

  //post private links to API
  const postPrivateLink = async () => {
    try {
      const privateLinkData = [
        {
          privacyStatus: phoneprivacyStatus,
          type: "Phone number",
          countryCode: phoneCountryCode,
          link: phoneNumber,
        },
        {
          privacyStatus: mailprivacyStatus,
          type: "Mailing Address",
          link: mailingAddress,
        },
        {
          privacyStatus: emailprivacyStatus,
          type: "Email Address",
          link: email,
        },
        {
          privacyStatus: whatsappprivacyStatus,
          type: "Whatsapp",
          countryCode: whatsappCountryCode,
          link: whatsapp,
        },
        {
          privacyStatus: telegramprivacyStatus,
          type: "Telegram",
          countryCode: telegramCountryCode,
          link: telegram,
        },
        {
          privacyStatus: viberprivacyStatus,
          type: "Viber",
          countryCode: viberCountryCode,
          link: viber,
        },
        {
          privacyStatus: wechatprivacyStatus,
          type: "Wechat",
          countryCode: wechatCountryCode,
          link: weChat,
        },
      ];

      const filledPrivateLinkData = privateLinkData.filter(
        (entry) => entry.link !== ""
      );

      await fetch(`${API_DOMAIN}/private/save/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          privateLinks: filledPrivateLinkData,
        }),
      });
      console.log("Successfully posted private links");
      // window.location.href = "/profile";
      console.log(filledPrivateLinkData);
      setPrivateLinkAdded(true);
    } catch (error) {
      console.error("Error in posting private links:", error);
    }
  };

  //update private links
  const updatePrivateLinks = async () => {
    try {
      const updateprivateLinkData = [
        {
          privacyStatus: phoneprivacyStatus,
          type: "Phone number",
          countryCode: phoneCountryCode,
          link: phoneNumber,
        },
        {
          privacyStatus: mailprivacyStatus,
          type: "Mailing Address",
          link: mailingAddress,
        },
        {
          privacyStatus: emailprivacyStatus,
          type: "Email Address",
          link: email,
        },
        {
          privacyStatus: whatsappprivacyStatus,
          type: "Whatsapp",
          countryCode: whatsappCountryCode,
          link: whatsapp,
        },
        {
          privacyStatus: telegramprivacyStatus,
          type: "Telegram",
          countryCode: telegramCountryCode,
          link: telegram,
        },
        {
          privacyStatus: viberprivacyStatus,
          type: "Viber",
          countryCode: viberCountryCode,
          link: viber,
        },
        {
          privacyStatus: wechatprivacyStatus,
          type: "Wechat",
          countryCode: wechatCountryCode,
          link: weChat,
        },
      ];

      const filledUpdatePrivateLinkData = updateprivateLinkData.filter(
        (entry) => entry.link !== ""
      );

      await fetch(`${API_DOMAIN}/private/update/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          updatedPrivateLinks: filledUpdatePrivateLinkData,
        }),
      });
      console.log("Successfully updated private links");
      console.log(filledUpdatePrivateLinkData);
    } catch (error) {
      console.error("Failed to update private links:", error);
    }
  };

  //delete private links
  const deletePrivateLinks = async (type) => {
    try {
      await fetch(`${API_DOMAIN}/private/delete/${id}/${type}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Private link is deleted succesfully", type);
      window.location.href = "/profile";
    } catch (error) {
      console.error("Failed to delete cprivate link");
    }
  };

  const handleToggle = (type, val) => {
    setCheckToggle((prevState) => ({
      ...prevState,
      [type]: val,
    }));
  };

  const handleOnClick = async () => {
    await postPrivateLink();
    await updatePrivateLinks();
    if (!checkToggle.phone) {
      await deletePrivateLinks("Phone number");
    } else if (!checkToggle.address) {
      await deletePrivateLinks("Mailing Address");
    } else if (!checkToggle.email) {
      await deletePrivateLinks("Email Address");
    } else if (!checkToggle.whatsapp) {
      await deletePrivateLinks("Whatsapp");
    } else if (!checkToggle.telegram) {
      await deletePrivateLinks("Telegram");
    } else if (!checkToggle.viber) {
      await deletePrivateLinks("Viber");
    } else if (!checkToggle.wechat) {
      await deletePrivateLinks("WeChat");
    }
  };

  return (
    <Popup
      trigger={
        <button className="add-pl">
          <IoMdAdd className="add-pl-icon" />
        </button>
      }
      modal
      nested
    >
      {(close) => (
        <div className="pl-popup">
          <div className="pl-popup-header">
            <div className="pl-box">
              <div className="pl-close">
                <button className="wp-close-btn" onClick={() => close()}>
                  <CloseButton style={{ height: "5px", width: "5px" }} />
                </button>
              </div>
              <div className="pl-title">
                <h> Add Private Links</h>
              </div>
            </div>
            <div className="selected-pl">
              <div className="selected-pl-details">
                <div className="select-pl-desc">
                  <div className="select-pl-icon">
                    <img src={PhoneNumber} alt="phone-icon" />
                  </div>
                  <div className="select-pl-title"> Phone Number </div>
                  <div className="toggle">
                    <span className="toggle-label">Off</span>
                    <ReactSwitch
                      checked={checkToggle.phone}
                      onChange={(val) => handleToggle("phone", val)}
                      onColor="#000000"
                      offColor="#D7D7D7"
                      handleDiameter={12}
                      height={18}
                      width={32}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    <span className="toggle-label">On</span>
                  </div>
                </div>

                <div className="select-pl-text">
                  <PhoneInput
                    country={"lk"}
                    enableSearch={true}
                    value={phoneNumber}
                    disabled={!checkToggle.phone}
                    placeholder="Phone Number"
                    onChange={(phoneNumber, country) => {
                      setPhoneNumber(phoneNumber);
                      setPhoneCountryCode(country.dialCode);
                    }}
                  />
                </div>
                <div className="select-pl-option">
                  <div className="pl-radio">
                    <input
                      type="radio"
                      name="pn-select"
                      checked={phoneprivacyStatus === "Public"}
                      onClick={() => setPhonePrivacyStatus("Public")}
                      className="radio-btn"
                    />{" "}
                    Public
                  </div>
                  <div className="pl-radio">
                    <input
                      type="radio"
                      name="pn-select"
                      onClick={() => setPhonePrivacyStatus("On-Request")}
                      checked={phoneprivacyStatus === "On-Request"}
                      className="radio-btn"
                    />{" "}
                    On Request
                  </div>
                </div>
              </div>
              <hr />
              <div className="selected-pl-details">
                <div className="select-pl-desc">
                  <div className="select-pl-icon">
                    <img src={Email} alt="email-icon" />
                  </div>
                  <div className="select-pl-title"> Email </div>
                  <div className="toggle">
                    <span className="toggle-label">Off</span>
                    <ReactSwitch
                      checked={checkToggle.email}
                      onChange={(val) => handleToggle("email", val)}
                      onColor="#000000"
                      offColor="#D7D7D7"
                      handleDiameter={12}
                      height={18}
                      width={32}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    <span className="toggle-label">On</span>
                  </div>
                </div>

                <div className="select-pl-text">
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={!checkToggle.email}
                  />
                </div>
                <div className="select-pl-option">
                  <div className="pl-radio">
                    <input
                      type="radio"
                      name="em-select"
                      onClick={() => setEmailPrivacyStatus("Public")}
                      className="radio-btn"
                      checked={emailprivacyStatus === "Public"}
                    />{" "}
                    Public
                  </div>
                  <div className="pl-radio">
                    <input
                      type="radio"
                      name="em-select"
                      onClick={() => setEmailPrivacyStatus("On-Request")}
                      className="radio-btn"
                      checked={emailprivacyStatus === "On-Request"}
                    />{" "}
                    On Request
                  </div>
                </div>
              </div>
              <hr />
              <div className="selected-pl-details">
                <div className="select-pl-desc">
                  <div className="select-pl-icon">
                    <img src={MailingAddress} alt="address-icon" />
                  </div>
                  <div className="select-pl-title"> Mailing Address </div>
                  <div className="toggle">
                    <span className="toggle-label">Off</span>
                    <ReactSwitch
                      checked={checkToggle.address}
                      onChange={(val) => handleToggle("address", val)}
                      onColor="#000000"
                      offColor="#D7D7D7"
                      handleDiameter={12}
                      height={18}
                      width={32}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    <span className="toggle-label">On</span>
                  </div>
                </div>
                <div className="select-pl-text">
                  <Form.Control
                    type="text"
                    placeholder="Mailing Address"
                    value={mailingAddress}
                    onChange={(e) => setMailingAddress(e.target.value)}
                    disabled={!checkToggle.address}
                  />
                </div>
                <div className="select-pl-option">
                  <div className="pl-radio">
                    <input
                      type="radio"
                      value="Public"
                      name="ma-select"
                      onClick={() => setMailPrivacyStatus("Public")}
                      className="radio-btn"
                      checked={mailprivacyStatus === "Public"}
                    />{" "}
                    Public
                  </div>
                  <div className="pl-radio">
                    <input
                      type="radio"
                      value="Public"
                      name="ma-select"
                      onClick={() => setMailPrivacyStatus("On-Request")}
                      className="radio-btn"
                      checked={mailprivacyStatus === "On-Request"}
                    />{" "}
                    On Request
                  </div>
                </div>
              </div>
              <hr />
              <div className="selected-pl-details">
                <div className="select-pl-desc">
                  <div className="select-pl-icon">
                    <img src={Whatsapp} alt="whatsapp-icon" />
                  </div>
                  <div className="select-pl-title"> Whatsapp </div>
                  <div className="toggle">
                    <span className="toggle-label">Off</span>
                    <ReactSwitch
                      checked={checkToggle.whatsapp}
                      onChange={(val) => handleToggle("whatsapp", val)}
                      onColor="#000000"
                      offColor="#D7D7D7"
                      handleDiameter={12}
                      height={18}
                      width={32}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    <span className="toggle-label">On</span>
                  </div>
                </div>
                <div className="select-pl-text">
                  <PhoneInput
                    country={"lk"}
                    enableSearch={true}
                    value={whatsapp}
                    placeholder="Whatsapp"
                    disabled={!checkToggle.whatsapp}
                    onChange={(whatsapp, whatsappCountry) => {
                      setWhatsapp(whatsapp);
                      setWhatsappCountryCode(whatsappCountry.dialCode);
                    }}
                  />
                </div>
                <div className="select-pl-option">
                  <div className="pl-radio">
                    <input
                      type="radio"
                      value="Public"
                      name="wh-select"
                      onClick={() => setWhatsappPrivacyStatus("Public")}
                      className="radio-btn"
                      checked={whatsappprivacyStatus === "Public"}
                    />{" "}
                    Public
                  </div>
                  <div className="pl-radio">
                    <input
                      type="radio"
                      value="Public"
                      name="wh-select"
                      onClick={() => setWhatsappPrivacyStatus("On-Request")}
                      className="radio-btn"
                      checked={whatsappprivacyStatus === "On-Request"}
                    />{" "}
                    On Request
                  </div>
                </div>
              </div>
              <hr />
              <div className="selected-pl-details">
                <div className="select-pl-desc">
                  <div className="select-pl-icon">
                    <img
                      src={Telegram}
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                    />
                  </div>
                  <div className="select-pl-title"> Telegram </div>
                  <div className="toggle">
                    <span className="toggle-label">Off</span>
                    <ReactSwitch
                      checked={checkToggle.telegram}
                      onChange={(val) => handleToggle("telegram", val)}
                      onColor="#000000"
                      offColor="#D7D7D7"
                      handleDiameter={12}
                      height={18}
                      width={32}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    <span className="toggle-label">On</span>
                  </div>
                </div>
                <div className="select-pl-text">
                  <PhoneInput
                    country={"lk"}
                    enableSearch={true}
                    value={telegram}
                    placeholder="Telegram"
                    disabled={!checkToggle.telegram}
                    onChange={(telegram, telegramCountry) => {
                      setTelegram(telegram);
                      setTelegramCountryCode(telegramCountry.dialCode);
                    }}
                  />
                </div>
                <div className="select-pl-option">
                  <div className="pl-radio">
                    <input
                      type="radio"
                      value="Public"
                      name="te-select"
                      onClick={() => setTelegramPrivacyStatus("Public")}
                      className="radio-btn"
                      checked={telegramprivacyStatus === "Public"}
                    />{" "}
                    Public
                  </div>
                  <div className="pl-radio">
                    <input
                      type="radio"
                      value="Public"
                      name="te-select"
                      onClick={() => setTelegramPrivacyStatus("On-Request")}
                      className="radio-btn"
                      checked={telegramprivacyStatus === "On-Request"}
                    />{" "}
                    On Request
                  </div>
                </div>
              </div>
              <hr />
              <div className="selected-pl-details">
                <div className="select-pl-desc">
                  <div className="select-pl-icon">
                    <img
                      src={Viber}
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                    />
                  </div>
                  <div className="select-pl-title"> Viber </div>
                  <div className="toggle">
                    <span className="toggle-label">Off</span>
                    <ReactSwitch
                      checked={checkToggle.viber}
                      onChange={(val) => handleToggle("viber", val)}
                      onColor="#000000"
                      offColor="#D7D7D7"
                      handleDiameter={12}
                      height={18}
                      width={32}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    <span className="toggle-label">On</span>
                  </div>
                </div>
                <div className="select-pl-text">
                  <PhoneInput
                    country={"lk"}
                    enableSearch={true}
                    value={viber}
                    placeholder="Viber"
                    disabled={!checkToggle.viber}
                    onChange={(viber, viberCountry) => {
                      setViber(viber);
                      setViberCountryCode(viberCountry.dialCode);
                    }}
                  />
                </div>
                <div className="select-pl-option">
                  <div className="pl-radio">
                    <input
                      type="radio"
                      value="Public"
                      name="vi-select"
                      onClick={() => setviberPrivacyStatus("Public")}
                      className="radio-btn"
                      checked={viberprivacyStatus === "Public"}
                    />{" "}
                    Public
                  </div>
                  <div className="pl-radio">
                    <input
                      type="radio"
                      value="Public"
                      name="vi-select"
                      onClick={() => setviberPrivacyStatus("On-Request")}
                      className="radio-btn"
                      checked={viberprivacyStatus === "On-Request"}
                    />{" "}
                    On Request
                  </div>
                </div>
              </div>
              <hr />
              <div className="selected-pl-details">
                <div className="select-pl-desc">
                  <div className="select-pl-icon">
                    <img
                      src={WeChat}
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                    />
                  </div>
                  <div className="select-pl-title"> WeChat </div>
                  <div className="toggle">
                    <span className="toggle-label">Off</span>
                    <ReactSwitch
                      checked={checkToggle.wechat}
                      onChange={(val) => handleToggle("wechat", val)}
                      onColor="#000000"
                      offColor="#D7D7D7"
                      handleDiameter={12}
                      height={18}
                      width={32}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    <span className="toggle-label">On</span>
                  </div>
                </div>
                <div className="select-pl-text">
                  <PhoneInput
                    country={"lk"}
                    enableSearch={true}
                    value={weChat}
                    placeholder="WeChat"
                    disabled={!checkToggle.wechat}
                    onChange={(weChat, weChatcountry) => {
                      setWeChat(weChat);
                      setwechatCountryCode(weChatcountry.dialCode);
                    }}
                  />
                </div>
                <div className="select-pl-option">
                  <div className="pl-radio">
                    <input
                      type="radio"
                      value="Public"
                      name="wc-select"
                      onClick={() => setWechatPrivacyStatus("Public")}
                      className="radio-btn"
                      checked={wechatprivacyStatus === "Public"}
                    />{" "}
                    Public
                  </div>
                  <div className="pl-on-request">
                    <input
                      type="radio"
                      value="Public"
                      name="wc-select"
                      onClick={() => setWechatPrivacyStatus("On-Request")}
                      className="radio-btn"
                      checked={wechatprivacyStatus === "On-Request"}
                    />{" "}
                    On Request
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pl-popup-footer">
            <button className="add-btn" onClick={handleOnClick}>
              Update
            </button>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default PrivateLinks;
