import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import t1 from "../../Assets/ThemeRed.svg";
import t2 from "../../Assets/ThemeGreen.svg";
import t3 from "../../Assets/ThemeBlue.svg";
import StepBar from "../../Components/StepBar/StepBar";
import Footer from "../../Components/Footer/Footer";
import API_DOMAIN from "../../ApiConfig";
import "./SetTheme.css";

const SetTheme = () => {
  const [selectedTheme, setSelectedTheme] = useState("t2");
  const id = Cookies.get("id");

  const handleImageClick = (tid) => {
    setSelectedTheme(tid);
  };

  useEffect(() => {
    //Get theme data from Session Storage
    const savedTheme = sessionStorage.getItem("theme");

    if (savedTheme) setSelectedTheme(savedTheme);

    window.addEventListener("beforeunload", clearSessionStorage);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorage);
    };
  }, []);

  //Add theme data to Session Storage
  const handleClick = () => {
    sessionStorage.setItem("theme", selectedTheme);
  };

  //Clear theme data from Session Storage
  const clearSessionStorage = () => {
    sessionStorage.removeItem("theme");
  };

  const handleSubmit = () => {
    //Retrieve data from session storage
    const firstname = sessionStorage.getItem("firstName");
    const lastname = sessionStorage.getItem("lastName");
    const username = sessionStorage.getItem("userName");
    const image = sessionStorage.getItem("image");
    const bio = sessionStorage.getItem("bio");
    const country = sessionStorage.getItem("country");
    const city = sessionStorage.getItem("city");
    const position = sessionStorage.getItem("position");
    const workplace = sessionStorage.getItem("workPlace");

    //Send Theme to API
    const postTheme = async () => {
      try {
        await fetch(`${API_DOMAIN}/theme/update`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id, color: selectedTheme }),
        });
        console.log("Theme is successfully added to API");
        console.log({ id, color: selectedTheme });
      } catch (error) {
        console.error("Error in sending theme: ", error);
      }
    };

    //Send Username to API
    const postUsername = async () => {
      try {
        await fetch(`${API_DOMAIN}/login/update`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id,
            first_name: firstname,
            last_name: lastname,
            username: username,
          }),
        });
        console.log("Username is successfully added to API");
        console.log({
          id,
          first_name: firstname,
          last_name: lastname,
          username: username,
        });
      } catch (error) {
        console.error("Error in sending Username: ", error);
      }
    };

    //Send profile photo to API
    const postPhoto = async () => {
      try {
        await fetch(`${API_DOMAIN}/profileimage/upload`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id, image: image }),
        });
        console.log("Profile photo is successfully added to API");
        console.log({ id, image: image });
      } catch (error) {
        console.error("Error in sending profile photo: ", error);
      }
    };

    //Send bio description to API
    const postBio = async () => {
      try {
        await fetch(`${API_DOMAIN}/bio`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id, bio: bio }),
        });
        console.log("Bio description is successfully added to API");
        console.log({ id, bio: bio });
      } catch (error) {
        console.error("Error in sending bio description: ", error);
      }
    };

    //Send Country and City to API
    const postCountryCity = async () => {
      try {
        await fetch(`${API_DOMAIN}/location`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({id,country:country,city:city}),
        });
        console.log("Country is successfully added to API");
        console.log({id,country:country,city:city});
      } catch (error) {
        console.error("Error in sending Country: ", error);
      }
    };

    //Send Designation to API
    const postDesignation = async () => {
      try {
        await fetch(`${API_DOMAIN}/designation`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({id,position}),
        });
        console.log("Designation is successfully added to API");
        console.log({id,position});
      } catch (error) {
        console.error("Error in sending Designation: ", error);
      }
    };

    //Send Workplace to API
    const postWorkplace = async () => {
      try {
        await fetch(`${API_DOMAIN}/Work`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({id,work_place:workplace}),
        });
        console.log("Workplace is successfully added to API");
        console.log({id,work_place:workplace});
      } catch (error) {
        console.error("Error in sending Workplace: ", error);
      }
    };

    postUsername();
    postPhoto();
    postBio();
    postCountryCity();
    postDesignation();
    postWorkplace();
    postTheme();
  };

  return (
    <div
      className={`theme-container ${
        selectedTheme === "t1"
          ? "theme-t1"
          : selectedTheme === "t3"
          ? "theme-t3"
          : "theme-t2"
      }`}
    >
      <div className="theme-wrapper">
        <div className="theme-header">
          <StepBar num={4} selectedTheme={selectedTheme} />
        </div>
        <div className="theme-body">
          <div className="theme-topic">
          Let's craft your Tinly experience together.
          </div>

          <div className="theme-sub-topic">
            <h>Set a Colour Theme</h>
          </div>
          <div className="theme-select">
            <input
              type="radio"
              name="slider"
              id="t1"
              checked={selectedTheme === "t1"}
              onChange={() => handleImageClick("t1")}
            />
            <input
              type="radio"
              name="slider"
              id="t2"
              checked={selectedTheme === "t2"}
              onChange={() => handleImageClick("t2")}
            />
            <input
              type="radio"
              name="slider"
              id="t3"
              checked={selectedTheme === "t3"}
              onChange={() => handleImageClick("t3")}
            />

            <div className="themes">
              <label htmlFor="t1" id="theme-red">
                <div className="card">
                  <img src={t1} alt="" onClick={handleClick}/>
                </div>
              </label>
              <label htmlFor="t2" id="theme-green">
                <div className="card">
                  <img src={t2} alt="" onClick={handleClick}/>
                </div>
              </label>
              <label htmlFor="t3" id="theme-blue">
                <div className="card">
                  <img src={t3} alt="" onClick={handleClick}/>
                </div>
              </label>
            </div>
            <div className="theme-note">You can set more colors later.</div>
          </div>

          <div className="theme-button">
            <Link to="/demo">
              <button className="btn-prev" onClick={handleClick}>
                {" "}
                &lt; Previous{" "}
              </button>
            </Link>
            <Link to="/profile">
              <button
                className={`btn-save ${
                  selectedTheme === "t1"
                    ? "btn-t1"
                    : selectedTheme === "t3"
                    ? "btn-t3"
                    : "btn-t2"
                }`}
                onClick={handleSubmit}
              >
                Save
              </button>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SetTheme;
