import React, { useState } from "react";
import Popup from "reactjs-popup";
import CloseButton from "react-bootstrap/CloseButton";
import Sites from "../../Assets/Sites.svg";
import "./AddSites.css";
import API_DOMAIN from "../../ApiConfig";

function AddSites() {
  const [siteLinkError, setSiteLinkError] = useState(false);
  const [validSiteLink, setValidSiteLink] = useState(false);
  const [siteLink, setSiteLink] = useState("");
  const [siteErrorMsg, setSiteErrorMsg] = useState(false)

  const handleSiteLinks = (e) => {
    const enteredSiteLink = e.target.value;
    setSiteLink(enteredSiteLink);
    const UrlCheck =
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
    if (enteredSiteLink.trim() !== " ") {
      setValidSiteLink(UrlCheck.test(enteredSiteLink));
      setSiteLinkError("Enter a valid URL (http:// or https://)");
    } else {
      setValidSiteLink(false);
    }
  };

  const postSiteLink = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/site`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: "505", url: siteLink }),
      });
      if (response.status === 200) {
        const responseData = await response.json();
        if (responseData.error === "URL is already exist") {
          setTimeout(() => {
            setSiteErrorMsg(true);
          }, 5000);
          setSiteErrorMsg("URL already exists");
        } else {
          console.log("Successfully posted site links:", siteLink);
          window.location.href = "/profile";
        }
      }
    } catch (error) {
      console.error("Failed to post site link");
    }
  };

 
  return (
    <Popup
      trigger={
        <div className="add-sites">
          <button className="add-sites-icon">
            <img src={Sites} alt="Sites" className="add-sites-icon" />
          </button>
        </div>
      }
      modal
      nested
    >
      {(close) => (
        <div className="add-sites-popup">
          <div className="add-sites-header">
            <div className="add-sites-close">
              <button className="add-sites-close-btn" onClick={() => close()}>
                <CloseButton style={{ height: "5px", width: "5px" }} />
              </button>
            </div>
            <div className="add-sites-title">
              <h> Add Sites </h>
            </div>
          </div>
          <div className="add-sites-body">
            <div className="add-sites-input">
              <label> Site URL </label>
              <input
                type="text"
                placeholder="Site URL"
                className="add-site-text"
                onChange={handleSiteLinks}
              />
              {validSiteLink ? (
                ""
              ) : (
                <div className="error-msg-site-link">{siteLinkError}</div>
              )}
              {siteErrorMsg && (
                <div className="error-msg-site-link">{siteErrorMsg}</div>
              )}
            </div>
            <div className="add-site-btn">
              <button className="site-add" onClick={postSiteLink}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default AddSites;
