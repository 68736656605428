import React from 'react'
import Logo from "../../Assets/TinlyLogo.png";
import './HomeLogo.css'

export default function HomeLogo() {
  return (
    <div className="home-btn">
            <img src={Logo} alt="" className="home-t-logo" />
          </div>
  )
}
