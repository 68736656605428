import React, { useState, useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import Popup from "reactjs-popup";
import CloseButton from "react-bootstrap/esm/CloseButton";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie'
import './SitesOption.css'
import API_DOMAIN from "../../ApiConfig";

function SitesOption({ siteId }) {
  const [siteLinkError, setSiteLinkError] = useState(false);
  const [validSiteLink, setValidSiteLink] = useState(false);
  const [updatedSiteLink, setUpdatedSiteLink] = useState("");
  const [siteErrorMsg, setSiteErrorMsg] = useState(false);

  const id = Cookies.get("id")

  const handleSiteLinks = (e) => {
    const enteredSiteLink = e.target.value;
    setUpdatedSiteLink(enteredSiteLink);
    const UrlCheck =
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
    if (enteredSiteLink.trim() !== " ") {
      setValidSiteLink(UrlCheck.test(enteredSiteLink));
      setSiteLinkError("Enter a valid URL (http:// or https://)");
    } else {
      setValidSiteLink(false);
    }
  };

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/site/view?id=${id}`);
        if (!response) {
          throw new Error("Failed to fetch sites");
        }
        const res = await response.json();
        const mySiteUrl = jwtDecode(res.token);
        const UrlofSites = mySiteUrl.url;
        console.log("URL of Sites:", UrlofSites)
        const filteredLinks = UrlofSites.find((link) => link.pk_id === siteId);
        if (filteredLinks) {
          setUpdatedSiteLink(filteredLinks.site_url);
        }
        console.log("Filtere Links:",filteredLinks.site_url)
      } catch (error) {
        console.error("Failed to fetch your sites", error);
      }
    };
    fetchSites();
  }, [id, siteId]);

  const updateSites = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/site/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userid: id,
          siteid: siteId,
          url: updatedSiteLink
        }),
      });
      if (response.status === 200) {
        const responseData = await response.json();
        if (responseData.error === "URL is already exist") {
          setTimeout(() => {
            setSiteLinkError(true);
          }, 5000);
          setSiteErrorMsg("URL already exists");
        } else {
          console.log(
            "Successfully updated site links:",
            updatedSiteLink,
            siteId
          );
          window.location.href = "/profile";
        }
      }
    } catch (error) {
      console.error("Failed update site link", error);
    }
  };

  const deleteSite = async () => {
    try {
      await fetch(`${API_DOMAIN}/site/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userid: id, siteid: siteId }),
      });
      console.log("Successfully deleted site link");
      window.location.href = "/profile";
    } catch (error) {
      console.error("Failed to delete site link:", error);
    }
  };

  return (
    <Popup
      trigger={
        <span className="sites-options">
          <BsThreeDots />
        </span>
      }
      modal
      nested
    >
      {(close) => (
        <div className="sites-options-popup">
          <div className="sites-options-header">
            <div className="sites-options-title">Site</div>
            <div className="sites-options-close" onClick={(close)}>
            <CloseButton style={{ height: "5px", width: "5px" }} />
            </div>
          </div>
          <div className="sites-options-body">
            <div className="add-sites-input">
              <label> Site URL </label>
              <input
                type="text"
                placeholder="Site URL"
                className="add-site-text"
                value={updatedSiteLink}
                onChange={handleSiteLinks}
              />
              {validSiteLink ? (
                ""
              ) : (
                <div className="error-msg-site-link">{siteLinkError}</div>
              )}
              {siteErrorMsg && (
                <div className="error-msg-site-link">{siteErrorMsg}</div>
              )}
            </div>
            <div className="add-site-btn">
              <div className="site-update" onClick={updateSites}>
                Update
              </div>
              <div className="site-remove" onClick={deleteSite}>
                Remove
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default SitesOption;
