import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai";
import API_DOMAIN from "../../ApiConfig";
import StepBar from "../../Components/StepBar/StepBar";
import "./LoginUser.css";

export default function LoginUser({ userName }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [navigateToBio, setNavigateToBio] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/login/detail/${userName}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }
        const token = await response.json();
        console.log(token);
        const { first_name, last_name, username, email } = jwtDecode(
          token.token
        );
        setFirstName(first_name);
        setLastName(last_name);
        setUserName(username);
        setEmail(email);
        console.log(token);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };
    fetchData();
  }, [userName]);

  // const updateDetails = async () => {
  //   try {
  //     await fetch(`${API_DOMAIN}/login/update`, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         id,
  //         first_name: firstName,
  //         last_name: lastName,
  //         username: username,
  //       }),
  //     });
  //     console.log(
  //       "Profile is updated succesfully:",
  //       firstName,
  //       lastName,
  //       username,
  //       email
  //     );
  //   } catch (error) {
  //     console.error("Failed to update user details:", error);
  //   }
  // };

  useEffect(() => {
    const savedFirstName = sessionStorage.getItem("firstName");
    const savedLastName = sessionStorage.getItem("lastName");
    const savedUserName = sessionStorage.getItem("userName");

    if (savedFirstName) setFirstName(savedFirstName);
    if (savedLastName) setLastName(savedLastName);
    if (savedUserName) setUserName(savedUserName);

    window.addEventListener("beforeunload", clearSessionStorage);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorage);
    };
  }, []);

  const handleClick = () => {
    // Check if any field is empty
    if (!firstName || !lastName || !username) {
      alert("Please fill in all fields.");
      return;
    }

    // Check if any validation fails
    if (errors.firstName || errors.lastName || errors.userName) {
      alert("Please input valid details before proceeding.");
      return;
    }

    sessionStorage.setItem("firstName", firstName);
    sessionStorage.setItem("lastName", lastName);
    sessionStorage.setItem("userName", username);
    // updateDetails();
    setNavigateToBio(true);
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem("firstName");
    sessionStorage.removeItem("lastName");
    sessionStorage.removeItem("userName");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    if (name === "firstName") {
      setFirstName(trimmedValue);
    } else if (name === "lastName") {
      setLastName(trimmedValue);
    } else if (name === "userName") {
      setUserName(trimmedValue);
    }
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "firstName" || name === "lastName") {
      if (trimmedValue.length < 3) {
        setErrors({
          ...errors,
          [name]: "Name should include at least 3 characters.",
        });
      }
    }
    if (name === "userName") {
      const userNamePattern = /^[a-z0-9]{4,16}$/;
      if (!userNamePattern.test(trimmedValue)) {
        setErrors({
          ...errors,
          [name]:
            "Username should be 4-16 characters long and contain only lowercase alphanumerical characters.",
        });
      }
    }
  };

  return (
    <div className="userLogin-container">
      <div className="ul-wrapper">
        <div className="ul-header">
          <StepBar num={1} />
        </div>
        <div className="ul-body">
          <div className="ulb-topic">
          Let's craft your Tinly experience together.
          </div>
          <div className="ulb-top">
            <div className="ulb-sub-topic">
              <h>Set your Name and Username</h>
            </div>
            <div className="ul-text">
              <div className="fname">
                <div className="name-label">First Name</div>
                <input
                  type="text"
                  placeholder="First Name"
                  className="name-text"
                  maxLength={25}
                  name="firstName"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    handleChange(e);
                  }}
                />
                <div className="error-message">
                  {errors.firstName && <p>{errors.firstName}</p>}
                </div>
              </div>
              <div className="lname">
                <div className="name-label">Last Name</div>
                <input
                  type="text"
                  placeholder="Last Name"
                  className="name-text"
                  maxLength={25}
                  name="lastName"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    handleChange(e);
                  }}
                />
                <div className="error-message">
                  {errors.lastName && <p>{errors.lastName}</p>}
                </div>
              </div>
            </div>
            <div className="uname">
              <div className="name-label">User Name</div>
              <div className="input-user-name">
                <span>@</span>
                <input
                  type="email"
                  placeholder="Username"
                  className="name-text"
                  maxLength={16}
                  name="userName"
                  value={username}
                  onChange={(e) => {
                    setUserName(e.target.value);
                    handleChange(e);
                  }}
                />
              </div>
              <div className="error-message">
                {errors.userName && <p>{errors.userName}</p>}
              </div>
            </div>
            <p className="email">Your Email : {email}</p>
            <div className="ul-body-note">
              <p>
                Please note: Name and Username can be changed only 3 times.
                Choose wisely!
              </p>
            </div>
          </div>
          <div className="ul-bottom">
            <Link to="/getstart">
              <button onClick={handleClick}> &lt; Previous </button>
            </Link>
            {navigateToBio ? (
              <Link to="/bio">
                <button> Next &gt; </button>
              </Link>
            ) : (
              <button onClick={handleClick}> Next &gt; </button>
            )}
          </div>
        </div>
        <div className="ul-footer">
        <p>
           Made &nbsp;<AiFillHeart className="heart-icon"/> &nbsp; with Tinly
          </p>
        </div>
      </div>
    </div>
  );
}
