import React, { useEffect, useRef, useState } from "react";
import { FaCamera } from "react-icons/fa";
import CloseButton from "react-bootstrap/CloseButton";
import API_DOMAIN from "../../ApiConfig";
import Cookies from "js-cookie";
import Popup from "reactjs-popup";
import "./ProfileImage.css";

export default function ProfileImage({ onImageChange }) {
  const fileInputRef = useRef(null);
  const [previewImage, setPreviewImage] = useState("");
  const [imageAdded, setImageAdded] = useState(false);
  const [newImage, setNewImage] = useState("");
  const [userPhoto,setUserPhoto]=useState("");
  const id = Cookies.get("id");

  const handleAddImageClick = () => {
    fileInputRef.current.click();
  };

  //fetch profile photo
  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/profileimage/view?fk_user_id=${id}`
        );
        console.log(response);
        if (!response.ok) {
          throw new Error("Failed to fetch profile image");
        }
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setPreviewImage(imageUrl);
        setUserPhoto(imageUrl);
        setImageAdded(true);
      } catch (error) {
        console.error("Error in fetching profile image:", error);
      }
    };
    fetchProfileImage();
  }, [id]);

  //post profile photo
  const postProfileImage = async (image) => {
    try {
      const fk_user_id = id;

      const formData = new FormData();
      formData.append("image", image);
      formData.append("fk_user_id", fk_user_id);

      console.log(image);
      console.log(fk_user_id);
      const response = await fetch(`${API_DOMAIN}/profileimage/upload`, {
        method: "POST",
        body: formData,
      });
      console.log(formData);
      if (response.ok) {
        console.log("Profile Image uploaded successfully");
        setImageAdded(true);
      } else {
        console.error("Failed to upload profile image");
      }
    } catch (error) {
      console.error("Error in posting profile image:", error);
    }
  };

  //delete profile photo
  const deleteProfileImage=async()=>{
    try{
      await fetch(`${API_DOMAIN}/profileimage/delete?fk_user_id=${id}`,{
        method:"DELETE",
        headers:{
          "Content-Type":"application/json",
        },
        body:JSON.stringify(),
      });
      setImageAdded(false);
      setPreviewImage("");
    }
    catch(error){
      console.error("Error in deleting profile photo:", error);
    }
  }

  const handleImageAdd = (e) => {
    const image = e.target.files[0];
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setUserPhoto(reader.result);
        onImageChange(reader.result);
        postProfileImage(image); // Call postProfileImage function with the file
      };
      reader.readAsDataURL(image);
    }
  };

  const handleChangeImage = (e) => {
    const newImage = e.target.files[0];
    if (newImage) {
      setNewImage(newImage); // Update the state with the new image
      const reader = new FileReader();
      reader.onloadend = () => {
        // setPreviewImage(reader.result);
        // onImageChange(reader.result);
        // postProfileImage(image); // Call postProfileImage function with the file
      };
      reader.readAsDataURL(newImage);
    }
  };

  const handleRemoveImage = (e) => {
    setUserPhoto("");
    setNewImage("");
  };

  const handleSubmit = (close) => {
    if (newImage) {
      setPreviewImage(URL.createObjectURL(newImage));
      setImageAdded(true);
      postProfileImage(newImage);
    } else if(userPhoto==="") {
      deleteProfileImage();
    }
    else{
      console.log("User photo never changed")
    }
    close();
  };

  return (
    <div className="profile-image">
      {imageAdded ? (
        <Popup
          trigger={
            <img src={previewImage} alt="Profile" className="preview-image" />
          }
          modal
          nested
        >
          {(close) => (
            <div className="photo-edit-popup">
              <div className="pep-header">
                <div className="pep-title">
                  <h>Profile Photo</h>
                </div>
                <button className="pep-close-btn" onClick={() => close()}>
                  <CloseButton style={{ height: "5px", width: "5px" }} />
                </button>
              </div>

              <div className="pep-body">
                <div className="pep-body-left">
                  <img
                    src={
                      (newImage && URL.createObjectURL(newImage)) || userPhoto
                    }
                    alt="Add New"
                    className="user-photo"
                  />
                </div>
                <div className="pep-body-right">
                  <div
                    className="add-new"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="input-img"
                      ref={fileInputRef}
                      onChange={handleChangeImage}
                      hidden
                    />
                    <p className="add-new-btn">Add New</p>
                  </div>
                  <button className="remove" onClick={handleRemoveImage}>
                    Remove
                  </button>
                </div>
              </div>

              <div className="pep-footer">
                <button
                  className="save-btn"
                  onClick={() => handleSubmit(close)}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </Popup>
      ) : (
        <div>
          <input
            type="file"
            className="add-image-file"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleImageAdd}
          />
          <FaCamera className="add-image-icon" onClick={handleAddImageClick} />
        </div>
      )}
    </div>
  );
}
