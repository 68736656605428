import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import { BsThreeDots } from "react-icons/bs";
import { TbFileUpload } from "react-icons/tb";
import CloseButton from "react-bootstrap/CloseButton";
import API_DOMAIN from "../../ApiConfig";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

export default function CustomLinks({customId}) {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [customLinks, setCustomLinks] = useState("");
  const [validCustomLinks, setValidCustomLinks] = useState(false);
  const [customLinkError, setCustomLinkError] = useState(false);
  const [customLinkTitle, setCustomLinkTitle] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);

  const id = Cookies.get("id");

  const handleCustomLinks = (e) => {
    const enteredCustomLink = e.target.value;
    const UrlCheck =
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
    setCustomLinks(enteredCustomLink);
    if (enteredCustomLink.trim() !== " ") {
      setValidCustomLinks(UrlCheck.test(enteredCustomLink));
      setCustomLinkError("Enter a valid URL (https:// or http://)");
    } else {
      setValidCustomLinks(true);
    }
  };

  //fetch custom link image
  useEffect(() => {
    const fetchCustomImage = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/customlinks/viewImage?custom_id=${customId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch custom image");
        }
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        setImage(imageUrl);
      } catch (error) {
        console.error("Failed to fetch custom Image:", error);
      }
    };
    fetchCustomImage();
  }, [customId]);

  //fetch custom links
  useEffect(() => {
    const fetchCustomLinks = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/customlinks/viewLink?pk_idd=${id}`
        );
        if (!response) {
          throw new Error("Failed to fetch custom links");
        }
        const token = await response.json();
        if (token.success) {
          const { row } = jwtDecode(token.token);
          console.log(row);
          const filteredLinks = row.find(
            (link) => link.pk_id === customId
          )
          if(filteredLinks){
            setCustomLinks(filteredLinks.url);
            setCustomLinkTitle(filteredLinks.title)
          }
        }
      } catch (error) {
        console.error("Failed to fetch custom link:", error);
      }
    };
    fetchCustomLinks();
  }, [id, customId]);


  const updateCustomLinks = async () => {
    try {
      const c_id = customId;
      const pk_idd = id;
      const custom_links = customLinks;
      const title = customLinkTitle;
      const image = selectedFile;

      const formData = new FormData();
      formData.append("c_id", c_id)
      formData.append("custom_links", custom_links);
      formData.append("title", title);
      formData.append("pk_idd", pk_idd);
      formData.append("image", image);

      const response = await fetch(`${API_DOMAIN}/customlinks/update`, {
        method: "PUT",
        body: formData,
      });
      if(response.status === 200) {
        const responseData = await response.json();
        if (responseData.error === "URL is already exist") {
          setTimeout(()=>{
            setErrorMsg(true);
          },5000)
          setErrorMsg("URL already exists")
        } else {
          console.log("Successfully posted custom links");
          console.log(customLinks, customLinkTitle, pk_idd, image);
          window.location.href = "/profile";
        }
      }
    } catch (error) {
      console.error("Failed to update custom links:", error);
    }
  };

  const deleteCustomLinks = async () => {
    try {
      const pk_idd = id;
      const c_id = customId;
      await fetch(`${API_DOMAIN}/customlinks/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pk_idd, c_id }),
      });
      console.log("Successfully deleted custom links");
      window.location.href = "/profile";
    } catch (error) {
      console.eeror("Failed to delete custom links:", error);
    }
  };

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(URL.createObjectURL(image));
        setSelectedFile(image);
      };
      reader.readAsDataURL(image);
    }
  };

  const handleChangeImage = (e) => {
    const newImage = e.target.files[0];
    if (newImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(URL.createObjectURL(newImage)); // Update the image preview
        setSelectedFile(newImage); // Update the selected file
      };
      reader.readAsDataURL(newImage);
    }
  };

  const handleRemoveImage = () => {
    setImage(""); // Clear the displayed image
    setSelectedFile(""); // Reset selectedFile state to empty string or null
  };

  return (
    <Popup
      trigger={
        <div className="custom-options">
          <BsThreeDots />
        </div>
      }
      modal
      nested
    >
      {(close) => (
        <div className="sm-popup">
          <div className="smp-header">
            <div className="smp-close">
              <button className="smp-close-btn" onClick={() => close()}>
                <CloseButton style={{ height: "5px", width: "5px" }} />
              </button>
            </div>
            <div className="smp-title">
              <h> {customLinkTitle} </h>
            </div>
          </div>
          <div className="custom-body">
            <div className="input-sec">
              <label>Website URL</label>
              <input
                type="text"
                className="txt-url"
                placeholder="https://url or http://url"
                value={customLinks}
                onChange={handleCustomLinks}
                required
              />
               {validCustomLinks ? "" : (
                  <div className="error-msg-custom-link">{customLinkError}</div>
                )}
                {errorMsg && (
                  <div className="error-msg-custom-link">{errorMsg}</div>
                )}
            </div>

            <div className="input-sec">
              <label>Website Image or Logo</label>
              <div className="add-img-sec">
                {image ? (
                  <div className="uploaded-img-container">
                    <img
                      src={image}
                      alt="Website Logo"
                      className="uploaded-img"
                    />
                    <div className="btn-group">
                      <button
                        className="btn-change"
                        onClick={() => fileInputRef.current.click()}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className="input-img"
                          ref={fileInputRef}
                          onChange={handleChangeImage}
                          hidden
                        />
                        Change Image
                      </button>
                      <button
                        className="btn-remove"
                        onClick={handleRemoveImage}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => document.querySelector(".input-img").click()}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      className="input-img"
                      onChange={handleImageChange}
                      hidden
                    />
                    <div className="upload-area">
                      <TbFileUpload className="upload-icon" />
                      <p className="upload-desc">
                        Upload a picture in BMP, JPG, JPEG or PNG format.
                        <br /> Maximum file size: 100MB
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="input-sec">
              <label>Website Title</label>
              <input
                type="text"
                className="txt-url"
                placeholder="Title"
                value={customLinkTitle}
                onChange={(e) => setCustomLinkTitle(e.target.value)}
                required
              />
            </div>
            <div className="bio-update-delete">
              <button className="bio-update" onClick={updateCustomLinks}>
                Update
              </button>
              <button className="bio-delete" onClick={deleteCustomLinks}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}
