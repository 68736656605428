import React from "react";
// import QrReader from "react-qr-scanner";
import { RxCross2 } from "react-icons/rx";
import "./QRReader.css";

export default function QRReader({ closeScanModal }) {
  // const [result, setResult] = useState("No QR code detected");

  // const handleScan = (data) => {
  //   if (data) {
  //     setResult(data);
  //   }
  // };

  // const handleError = (error) => {
  //   console.error(error);
  // };

  //prevent close modal when click inside
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="scan-qr-popup" onClick={handleModalClick}>
      <div className="scan-qr-header">
        <div className="scan-qr-title">Scan your Friend's QR</div>
        <div
          className="scan-qr-close"
          onClick={() => {
            closeScanModal(false);
          }}
        >
          <RxCross2 />
        </div>
      </div>

      <div className="scan-qr-body">
        <div className="qr-reader">
          {/* <QrReader delay={300} onError={handleError} onScan={handleScan} /> */}
        </div>
        <div className="qr-reader-msg">No QR code detected</div>
      </div>

      <div className="scan-qr-footer">
        <div className="scan-qr-btn">Scan</div>
      </div>
    </div>
  );
}
