import React, { useState, useEffect, useRef } from "react";
import Lottie from "react-lottie";
import animationData from "../../Lottie/ConnectionIcon1.json";
import { jwtDecode } from "jwt-decode";
import { BsYoutube, BsInstagram, BsSnapchat } from "react-icons/bs";
import { BsTwitterX } from "react-icons/bs";
import {
  BiLogoFacebook,
  BiLogoTiktok,
  BiLogoPatreon,
  BiLogoLinkedin,
} from "react-icons/bi";
import { FaPinterestP, FaTumblr } from "react-icons/fa";
import { SlSocialVkontakte } from "react-icons/sl";
import { TbWorld } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { SlOptions } from "react-icons/sl";
import { IoSettingsOutline } from "react-icons/io5";
import Logo from "../../Assets/TinlyLogo.png";
import Share from "../../Assets/Share.svg";
import Sites from "../../Assets/Sites.svg";
import Shortlys from "../../Assets/Shortlys.svg";
import Circles from "../../Assets/Circle.svg";
import Fab from "../../Assets/Fab.svg";
import Phone from "../../Assets/Phone.svg";
import Address from "../../Assets/MailingAddress.svg";
import Mail from "../../Assets/Mail.svg";
import Whatsapp from "../../Assets/whatsapp_white_1.svg";
import { FaUnlockKeyhole } from "react-icons/fa6";
import Telegram from "../../Assets/Telegram.svg";
import Viber from "../../Assets/Viber.svg";
import Wechat from "../../Assets/WeChat.svg";
import API_DOMAIN from "../../ApiConfig";
import Footer from "../../Components/Footer/Footer";
import BioDescription from "../../Components/BioDescription/BioDescription";
import UserLocation from "../../Components/Location/UserLocation";
import UserDesignation from "../../Components/UserDesignation/UserDesignation";
import WorkPlace from "../../Components/WorkPlace/WorkPlace";
import ProfileImage from "../../Components/ProfileImage/ProfileImage";
import SMLinks from "../../Components/SMLinks/SMLinks";
import PrivateLinks from "../../Components/PrivateLinks/PrivateLinks";
import SMOptions from "../../Components/SMOptions/SMOptions";
import "./DefaultProfile.css";
import CustomLinks from "../../Components/CustomLinks/CustomLinks";
import AddSites from "../../Components/AddSites/AddSites";
import Notification from "../../Components/Notification/Notification";
import Settings from "../../Components/Settings/Settings";
import SitesOption from "../../Components/SitesOption/SitesOption";
import ShareProfile from "../../Components/ShareProfile/ShareProfile";
import "./DefaultProfile.css";
import QRReader from "../../Components/QRReader/QRReader";

export default function DefaultProfile({ id }) {
  const [isFabOpen, setIsFabOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [user_name, setUserName] = useState("");
  const [isSMAdded, setSMAdded] = useState(false);
  const [isPLAdded, setPLAdded] = useState(false);
  const [customLinks, setCustomLinks] = useState("");
  const [customLinkAdded, setCustomLinkAdded] = useState(false);
  const [, setCustomImageAdded] = useState(false);
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [privateLinks, setPrivateLinks] = useState([]);
  const [modalSites, setModalSites] = useState(false);
  const [modalOptions, setModalOptions] = useState(false);
  const [modalSettings, setModalSettings] = useState(false);
  const [modalShareOther, setModalShareOther] = useState(false);
  const [modalShareOwn, setModalShareOwn] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [modalScanQr, setModalScanQr] = useState(false);
  const [customImages, setCustomImages] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [template, setTemplate] = useState("");
  const [sites, setSites] = useState("");
  const [siteAdded, setSiteAdded] = useState(false);
  const modalSitesRef = useRef(null);
  const modalOptionsRef = useRef(null);
  const modalSettingsRef = useRef(null);
  const modalShareOtherRef = useRef(null);
  const modalShareOwnRef = useRef(null);
  const modalScanRef = useRef(null);

  // Close modal when clicked outside
  const handleClickOutside = (e) => {
    if (modalSitesRef.current && !modalSitesRef.current.contains(e.target)) {
      setModalSites(false);
    }
    if (
      (modalOptionsRef.current &&
        !modalOptionsRef.current.contains(e.target)) ||
      (modalSettingsRef.current && !modalSettingsRef.current.contains(e.target))
    ) {
      setModalOptions(false);
      setModalSettings(false);
    }
    if (
      modalShareOtherRef.current &&
      !modalShareOtherRef.current.contains(e.target)
    ) {
      setModalShareOther(false);
    }
    if (
      modalShareOwnRef.current &&
      !modalShareOwnRef.current.contains(e.target)
    ) {
      setModalShareOwn(false);
    }
    if (modalScanRef.current && !modalScanRef.current.contains(e.target)) {
      setModalScanQr(false);
    }
  };

  //prevent close modal when click inside
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  //change navbar background when scrolling body
  const changeBackground = () => {
    if (window.scrollY > 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleChange = (imageData) => {
    console.log("Image changed:", imageData);
  };

  const setIconType = (type) => {
    switch (type) {
      case "Facebook":
        return <BiLogoFacebook />;
      case "Instagram":
        return <BsInstagram />;
      case "X":
        return <BsTwitterX />;
      case "Youtube":
        return <BsYoutube />;
      case "TikTok":
        return <BiLogoTiktok />;
      case "Pinterest":
        return <FaPinterestP />;
      case "Patreon":
        return <BiLogoPatreon />;
      case "Tumblr":
        return <FaTumblr />;
      case "VK":
        return <SlSocialVkontakte />;
      case "LinkedIn":
        return <BiLogoLinkedin />;
      case "Snapchat":
        return <BsSnapchat />;
      default:
        return null;
    }
  };

  const setPLType = (iconPl) => {
    switch (iconPl) {
      case "Phone number":
        return (
          <div className="pl-icon-sec">
            <img src={Phone} alt="" className="pl-icon-img" />
            <div className="pl-delete-icon">
              <RiDeleteBinLine onClick={() => deletePrivateLinks(iconPl)} />
            </div>
          </div>
        );
      case "Email Address":
        return (
          <div className="pl-icon-sec">
            <img src={Mail} alt="" className="pl-icon-img" />
            <div className="pl-delete-icon">
              <RiDeleteBinLine onClick={() => deletePrivateLinks(iconPl)} />
            </div>
          </div>
        );
      case "Mailing Address":
        return (
          <div className="pl-icon-sec">
            <img src={Address} alt="" className="pl-icon-img" />
            <div className="pl-delete-icon">
              <RiDeleteBinLine onClick={() => deletePrivateLinks(iconPl)} />
            </div>
          </div>
        );
      case "Whatsapp":
        return (
          <div className="pl-icon-sec">
            <img src={Whatsapp} alt="" className="pl-icon-img" />
            <div className="pl-delete-icon">
              <RiDeleteBinLine onClick={() => deletePrivateLinks(iconPl)} />
            </div>
          </div>
        );
      case "Telegram":
        return (
          <div className="pl-icon-sec">
            <img src={Telegram} alt="" className="pl-icon-img" />
            <div className="pl-delete-icon">
              <RiDeleteBinLine onClick={() => deletePrivateLinks(iconPl)} />
            </div>
          </div>
        );
      case "Viber":
        return (
          <div className="pl-icon-sec">
            <img src={Viber} alt="" className="pl-icon-img" />
            <div className="pl-delete-icon">
              <RiDeleteBinLine onClick={() => deletePrivateLinks(iconPl)} />
            </div>
          </div>
        );
      case "WeChat":
        return (
          <div className="pl-icon-sec">
            <img src={Wechat} alt="" className="pl-icon-img" />
            <div className="pl-delete-icon">
              <RiDeleteBinLine onClick={() => deletePrivateLinks(iconPl)} />
            </div>
          </div>
        );
      case "On-Request":
        return <FaUnlockKeyhole className="pl-icon-request" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchCustomLinks = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/customlinks/viewLink?pk_idd=${id}`
        );
        if (!response) {
          throw new Error("Failed to fetch custom links");
        }
        const token = await response.json();
        if (token.success) {
          const { row } = jwtDecode(token.token);
          console.log(row);
          const cLinks = row.map((link) => ({
            c_Id: link.pk_id,
            customUrl: link.url,
            customTitle: link.title,
          }));
          console.log(cLinks);
          setCustomLinkAdded(true);
          setCustomLinks(cLinks);
          // Fetch custom images
          for (const link of cLinks) {
            await fetchCustomImage(link.c_Id);
          }
        }
      } catch (error) {
        console.error("Failed to fetch custom link:", error);
      }
    };

    const fetchCustomImage = async (customId) => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/customlinks/viewImage?custom_id=${customId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch custom image");
        }
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        setCustomImages((prevImages) => [
          ...prevImages,
          { customId, imageUrl },
        ]);
        console.log(imageUrl);
        console.log("ID:", customId);
        setCustomImageAdded(true);
      } catch (error) {
        console.error("Failed to fetch custom Image:", error);
      }
    };
    fetchCustomLinks();
  }, [id]);

  //fetch social media links
  useEffect(() => {
    const fetchSMLinks = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/socialmedia/view/${id}`);
        if (!response) {
          throw new Error("Failed to fetch social media links");
        }
        const token = await response.json();

        if (token.success) {
          console.log(token);
          const { socialMediaLinks } = jwtDecode(token.token);
          console.log(socialMediaLinks);
          const smLinks = socialMediaLinks
            .map((link) => {
              const smUsername = link.sm_username;
              if (smUsername === "N/A") {
                return null; // Skip this entry if userName = N/A
              } else {
                setSMAdded(true);
                return {
                  icon: setIconType(link.type),
                  type: link.type,
                  smUsername: smUsername,
                };
              }
            })
            .filter(Boolean); //removing null entries

          setSocialMediaLinks(smLinks);
          console.log("sm Links:", smLinks);
        }
      } catch (error) {
        console.error("Error in fetching social media links:", error);
      }
    };

    fetchSMLinks();
  }, [id]);

  //delete private links
  const deletePrivateLinks = async (type) => {
    try {
      await fetch(`${API_DOMAIN}/private/delete/${id}/${type}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Private link is deleted succesfully", type);
      window.location.href = "/profile";
    } catch (error) {
      console.error("Failed to delete cprivate link");
    }
  };

  //fetch private links
  useEffect(() => {
    const fetchprivateLinks = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/private/view/${id}`);
        if (!response) {
          throw new Error("Failed to fetch private links");
        }
        const token = await response.json();
        if (token.success) {
          const { privateLinks } = jwtDecode(token.token);
          console.log(privateLinks);
          const pLinks = privateLinks.map((link) => ({
            plIcon: setPLType(link.type),
            privateLink: link.type,
            privacy_status: link.privacy_status,
          }));
          setPrivateLinks(pLinks);
          console.log(pLinks);
          console.log({ setPLType });
          setPLAdded(true);
        }
      } catch (error) {
        console.error("Failed to fetch Private Links", error);
      }
    };
    fetchprivateLinks();
  }, [id]);

  //fetch user data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/user/view?id=${id}`);
        if (!response) {
          throw new Error("Failed to fetch user details");
        }
        const token = await response.json();
        console.log(token);
        const { fullName, userName } = jwtDecode(token.token);
        setFullName(fullName);
        setUserName(userName);
        console.log(token);
        console.log(fullName, user_name);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };
    fetchData();
  });

  //fetch first name seperately
  useEffect(() => {
    const fetchFisrstName = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/user?id=${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }
        const token = await response.json();
        console.log(token);
        const { firstName } = jwtDecode(token.token);
        console.log(firstName);
        setFirstName(firstName);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };
    fetchFisrstName();
  });

  const toggleFab = () => {
    setIsFabOpen(!isFabOpen);
  };

  //fetch theme
  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/theme/view/?id=${id}`);
        if (!response) {
          throw new Error("Failed to fetch theme");
        }
        const theme = await response.json();
        const { color } = jwtDecode(theme.token);
        setSelectedTheme(color);
        console.log(color);
      } catch (error) {
        console.error("failed to fetch theme: ", error);
      }
    };

    fetchTheme();
  }, [id]);

  //Fetch template
  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const resTemplate = await fetch(
          `${API_DOMAIN}/template/view/?id=${id}`
        );
        if (!resTemplate) {
          throw new Error("Failed to fetch template!");
        }
        const templateType = await resTemplate.json();
        const { template } = jwtDecode(templateType.token);
        setTemplate(template);
        console.log(template);
      } catch (error) {
        console.error("Failed to fetch template: ", error);
      }
    };

    fetchTemplate();
  }, [id]);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/site/view?id=${id}`);
        if (!response) {
          throw new Error("Failed to fetch sites");
        }
        const res = await response.json();
        const mySiteUrl = jwtDecode(res.token);
        const UrlofSites = mySiteUrl.url;
        console.log("Sites:", mySiteUrl);
        console.log("Url:", UrlofSites);
        const mySite = UrlofSites.map((link) => ({
          siteP_Id: link.pk_id,
          siteLink: link.site_url,
        }));
        console.log(mySite);
        setSiteAdded(true);
        setSites(mySite);
      } catch (error) {
        console.error("Failed to fetch your sites", error);
      }
    };
    fetchSites();
  }, [id]);

  return (
    <div
      className={`profile-wrapper ${
        selectedTheme === "t1"
          ? "theme-1"
          : selectedTheme === "t3"
          ? "theme-3"
          : "theme-2"
      }`}
    >
      {modalShareOwn && (
        <div className="sp-modal-own">
          <ShareProfile closeShareModal={setModalShareOwn} />
        </div>
      )}

      {modalShareOther && (
        <div className="sp-modal-other">
          <ShareProfile closeShareModal={setModalShareOther} />
        </div>
      )}

      {modalScanQr && (
        <div className="scan-qr-modal">
          <QRReader closeScanModal={setModalScanQr} />
        </div>
      )}

      <div
        className={`default-profile ${
          isSMAdded ? "default-profile-smadd" : "default-profile-smnot"
        }`}
      >
        <div
          className={`navbar ${navbar ? "navbar-active" : "navbar-inactive"}`}
        >
          <div className="home-btn">
            <img src={Logo} alt="" className="home-t-logo" />
          </div>

          <div
            className="profile-options-btn"
            onClick={() => {
              setModalOptions(true);
            }}
            ref={modalOptionsRef}
          >
            <SlOptions className="profile-options-icon" />
          </div>

          {modalOptions && (
            <div className="home-options-popup" onClick={handleModalClick}>
              <div
                className="hop-settings-sec"
                onClick={() => {
                  setModalSettings(true);
                  setModalOptions(false);
                }}
              >
                <div className="hop-settings-label">Settings</div>
                <IoSettingsOutline />
                <div className="hop-settings-icon"></div>
              </div>
            </div>
          )}

          {modalSettings && (
            <Settings
              closeModalSettings={setModalSettings}
              ref={modalSettingsRef}
            />
          )}
        </div>

        <div className="profile-top">
          <div className="section1">
            <div className="connections">
              <span className="connection-count">0</span>
              <Lottie
                options={defaultOptions}
                height={50}
                width={50}
                className="connection-icon"
              />
            </div>
            <ProfileImage onImageChange={handleChange} />
            <div
              className="share-profile"
              onClick={() => {
                setModalShareOther(true);
              }}
              ref={modalShareOtherRef}
            >
              <img src={Share} alt="" className="share-icon" />
            </div>
          </div>
          <div className="section2">
            <span className="name">{`${fullName}`}</span>
            <span className="user-name">@{user_name}</span>
          </div>
          <div className="section3" ref={modalSitesRef}>
            <div className="sites-wrapper">
              <img
                src={Sites}
                alt=""
                className="sites"
                onClick={() => {
                  setModalSites(true);
                }}
              />
            </div>
            {modalSites && (
              <div className="sites-view-popup">
                <div className="sites-vp-title">
                  {firstName}'s Sites
                  <div
                    className="sites-vp-close"
                    onClick={() => setModalSites(false)}
                  >
                    <RxCross2 />
                  </div>
                </div>
                <div className="sites-vp-body">
                  {siteAdded ? (
                    sites.map((link, index) => (
                      <div>
                        <div className="sites-vlist" key={index}>
                          <a href={link.siteLink}>
                            <div className="site-name">{link.siteLink} </div>
                          </a>
                          <span className="dsite-option">
                            <SitesOption siteId={link.siteP_Id} />
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="sites-vlist">No sites added</div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="section4">
            <BioDescription />
          </div>
          <div className="section5">
            <div className="demographic">
              <UserLocation />
            </div>
            <div className="demographic">
              <UserDesignation />
            </div>
            <div className="demographic">
              <WorkPlace />
            </div>
          </div>
        </div>
        <div className="profile-body">
          <div className="tabs">
            <div className="tab-section">
              <img src={Shortlys} alt="" className="tab-image" />
              <span className="tab-title">Shortlys</span>
              <span className="bar"></span>
            </div>
            <div className="tab-section">
              <img src={Circles} alt="" className="tab-image" />
              <span className="tab-title">Circles</span>
            </div>
          </div>

          <div className="add-links">
            <div className="dp-sm-links">
              {isSMAdded &&
                socialMediaLinks.map((link, index) => (
                  <div
                    className={`sm-links-added ${
                      template === "fill" ? "template-fill" : "template-border"
                    }`}
                    key={index}
                  >
                    <div className="sm-links-logo">{link.icon}</div>
                    <h className="sm-links-title">{link.type}</h>
                    <span>
                      <SMOptions type={link.type} />
                    </span>
                  </div>
                ))}
              {customLinkAdded &&
                customLinks.map((link, index) => {
                  const customImage = customImages.find(
                    (image) => image.customId === link.c_Id
                  );
                  return (
                    <div
                      className={`sm-links-added ${
                        template === "fill"
                          ? "template-fill"
                          : "template-border"
                      }`}
                      key={index}
                    >
                      {customImage ? (
                        <div className="cl-links-logo">
                          <img
                            src={customImage.imageUrl}
                            alt="Custom"
                            className="custom-image"
                          />
                        </div>
                      ) : (
                        <div className="sm-links-logo">
                          <TbWorld />
                        </div>
                      )}
                      <h className="sm-links-title">{link.customTitle}</h>
                      <div className="custom-options">
                        <CustomLinks customId={link.c_Id} />
                      </div>
                    </div>
                  );
                })}

              <div className="dp-social">
                <SMLinks />
              </div>
            </div>

            <div className="dp-p-links">
              <div className="pl-icons">
                {isPLAdded &&
                  privateLinks.map((link, plId) => (
                    <div className="pl-icon" key={plId}>
                      {link.plIcon}
                    </div>
                  ))}
              </div>
              <PrivateLinks />
            </div>
          </div>
        </div>
      </div>
      <div className="fab">
        <div className="notifications">
          <Notification />
        </div>
        <div className="user"></div>
        <div className="dots" onClick={toggleFab}>
          <img src={Fab} alt="" className="fab-icon" />
        </div>

        {isFabOpen && (
          <div className="fab-menu">
            <div
              className="share-own"
              onClick={() => {
                setModalShareOwn(true);
              }}
              ref={modalShareOwnRef}
            ></div>
            <div
              className="qr"
              onClick={() => {
                setModalScanQr(true);
              }}
              ref={modalScanRef}
            ></div>
            <div className="sites-add">
              <AddSites />
            </div>
          </div>
        )}
      </div>

      <div className="profile-bottom">
        <Footer />
      </div>
    </div>
  );
}
