import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import CloseButton from "react-bootstrap/CloseButton";
import Edit from "../../Assets/Edit.svg";
import API_DOMAIN from "../../ApiConfig";
import "./BioDescription.css";

export default function BioDescription({ id }) {
  const [bio, setBioDescription] = useState("");
  const [isBioAdded, setIsBioAdded] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [charCount, setCharCount] = useState(0);

  id = Cookies.get("id");

  const handleCount = (e) => {
    const { value } = e.target;
    setBioDescription(value);
    setCharCount(value.length);
  };

  //fetch bio description
  useEffect(() => {
    const fetchBio = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/bio/view?id=${id}`);
        console.log(response);
        if (!response.ok) {
          throw new Error("Failed to fetch bio description");
        }

        const token = await response.json();

        if (token.success) {
          console.log(token);
          const { bio } = jwtDecode(token.token);
          console.log(bio);
          setBioDescription(bio);
          setIsBioAdded(true);
        }
      } catch (error) {
        console.error("Failed to fetch bio description:", error);
      }
    };
    fetchBio();
  }, [id]);

  //Post bio to API
  const postBio = async () => {
    try {
      await fetch(`${API_DOMAIN}/bio`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, bio }),
      });
      console.log("Bio Description is successfully added to API");
      console.log({ id, bio });
      setIsBioAdded(true);
      setTimeout(() => {
        setSuccessMsg(true);
      }, 5000);
      setSuccessMsg("Bio Description is added successfully");
      window.location.href = "/profile";
    } catch (error) {
      console.error("Error in posting bio description:", error);
    }
  };

  //update bio description
  const updateBio = async () => {
    try {
      await fetch(`${API_DOMAIN}/bio/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, bio }),
      });
      console.log("Bio Description is updated successfully:", bio);
      setTimeout(() => {
        setSuccessMsg(true);
      }, 5000);
      setSuccessMsg("Bio Description is updated successfully");
      window.location.href = "/profile";
    } catch (error) {
      console.error("Error in updating bio description:", error);
    }
  };

  //Delete Bio Description
  const deleteBio = async () => {
    try {
      await fetch(`${API_DOMAIN}/bio/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      setIsBioAdded(false);
      setTimeout(() => {
        setSuccessMsg(true);
      }, 3000);
      setSuccessMsg("Bio Description is deleted successfully");
      setBioDescription("");
      window.location.href = "/profile";
    } catch (error) {
      console.error("Error in deleting bio description:", error);
    }
  };

  return (
    <Popup
      trigger={
        <button className="bio-description">
          <img src={Edit} alt="" className="edit-icon" />

          
          <span className="bio-desc">
            {isBioAdded
              ? 
              //Limit the display character count
              `${bio.slice(0, 90)}${bio.length > 90 ? "..." : ""}`
              : "Bio Description"}
          </span>
        </button>
      }
      modal
      nested
    >
      {(close) => (
        <div className="bio-popup">
          <div className="bio-popup-header">
            <div className="bp-box">
              <div className="bp-close">
                <button className="bp-close-btn" onClick={() => close()}>
                  <CloseButton style={{ height: "5px", width: "5px" }} />
                </button>
              </div>
              <div className="bp-title">
                <h> Bio Description </h>
              </div>
            </div>
          </div>
          <div className="bio-popup-body">
            <textarea
              rows={5}
              cols={35}
              value={bio}
              maxLength={150}
              onChange={(e) => {
                setBioDescription(e.target.value);
                handleCount(e);
              }}
              placeholder={"Add Your Bio Description Here"}
              className="bio-body"
            />
            <div className="char">{charCount}/150</div>
          </div>
          <div className="bio-popup-footer">
            {!isBioAdded ? (
              <button className="bp-save" onClick={postBio}>
                Save
              </button>
            ) : (
              <div className="bio-update-delete">
                <button className="bio-update" onClick={updateBio}>
                  Update
                </button>
                <button className="bio-delete" onClick={deleteBio}>
                  Delete
                </button>
              </div>
            )}
          </div>
          {successMsg && <div className="success-message">{successMsg}</div>}
        </div>
      )}
    </Popup>
  );
}
