import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import Axios from "axios";
import CloseButton from "react-bootstrap/CloseButton";
import Location from "../../Assets/Location.svg";
import API_DOMAIN from "../../ApiConfig";
import "./UserLocation.css";

export default function UserLocation({ id }) {
  const [countries, setCountries] = useState([]);
  const [Cities, setCities] = useState([]);
  const [isAdded, setIsAdded] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  id = Cookies.get("id");


  const fetchCountries = async () => {
    let country = await Axios.get(
      "https://countriesnow.space/api/v0.1/countries"
    );
    console.log(country);

    setCountries(country.data.data);
  };
  
  const fetchCities = (country) => {
    const Cities = countries.find((c) => c.country === country);
    setCities(Cities.cities);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleCountry = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    setSelectedCity("");
    fetchCities(country);
  };

  const handleCity = (e) => {
    const city = e.target.value;
    setSelectedCity(city);
  };

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/location/view?id=${id}`
        );
        console.log(response);
        if (!response.ok) {
          throw new Error("Failed to fetch location");
        }
        const token = await response.json();

        if (token.success) {
          console.log(token);
          const { country, city } = jwtDecode(token.msg);
          console.log(city, country);
          setSelectedCity(city);
          setSelectedCountry(country);
          setIsAdded(true);
        } else {
          console.error("Failed to fetch location:", token.error);
        }
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };
    fetchLocation();
  }, [id]);

  //Post location to API
  const postLocation = async () => {
    try {
      await fetch(`${API_DOMAIN}/location`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          country: selectedCountry,
          city: selectedCity,
          id,
        }),
      });
      console.log("Location is successfully added to API");
      console.log({ country: selectedCountry, city: selectedCity, id });
      setTimeout(() => {
        setSuccessMsg(true);
      }, 5000);
      setSuccessMsg("Location is added successfully");
      setIsAdded(true);
      window.location.href = "/profile";
    } catch (error) {
      console.error("Error in sending designation:", error);
    }
  };

  //Update location
  const updateLocation = async () => {
    try {
      await fetch(`${API_DOMAIN}/location/update`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          country: selectedCountry,
          city: selectedCity,
          id,
        }),
      });
      console.log("Location is updated successfully");
      console.log({
        country: selectedCountry,
        city: selectedCity,
        id,
      });
      setTimeout(() => {
        setSuccessMsg(true);
      }, 5000);
      setSuccessMsg("Location is updated successfully");
      window.location.href = "/profile";
    } catch (error) {
      console.error("Error in updating Location:", error);
    }
  };

  //Delete location
  const deleteLocation = async () => {
    try {
      await fetch(`${API_DOMAIN}/location/delete`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      console.log("Location is deleted successfully");
      setIsAdded(false);
      setTimeout(() => {
        setSuccessMsg(true);
      }, 5000);
      setSuccessMsg("Designation is deleted successfully");
      window.location.href = "/profile";
    } catch (error) {
      console.error("Error in deleting location:", error);
    }
  };

  return (
    <Popup
      trigger={
        <button className="demographic">
          <img src={Location} alt="" className="demo-icon" />
          <span className="demo-title">
            {isAdded ? (
              <div>{`${selectedCity.slice(0,20)}, ${selectedCountry.slice(0, Math.max(0, 20 - selectedCity.length - 2))}${selectedCity.length + selectedCountry.length > 20 ? "..." : ""}`}</div>
            ) : (
              <div>Location</div>
            )}
          </span>
        </button>
      }
      modal
      nested
    >
      {(close) => (
        <div className="location-popup">
          <div className="location-popup-header">
            <div className="location-box">
              <div className="location-close">
                <button className="location-close-btn" onClick={() => close()}>
                  <CloseButton style={{ height: "5px", width: "5px" }} />
                </button>
              </div>
              <div className="location-title">
                <h> Location </h>
              </div>
            </div>
          </div>
          <div className="location-body">
            <div className="location-country">
              {countries && (
                <select
                  className="select-country"
                  maxLength={60}
                  onChange={(e) => {
                    handleCountry(e);
                    fetchCities(e.target.value);
                  }}
                >
                  {isAdded ? (
                    <option selected hidden disabled>
                      {selectedCountry}
                    </option>
                  ) : (
                    <option selected hidden disabled>
                      Select your Country
                    </option>
                  )}
                  {countries.map((country) => (
                    <option
                      key={`${country.country}`}
                      value={country.country}
                      style={{ width: "50%" }}
                    >
                      {country.country}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="location-city">
              {Cities && (
                <select
                  className="select-city"
                  maxLength={60}
                  onChange={handleCity}
                >
                  {isAdded ? (
                    <option selected hidden disabled>
                      {selectedCity}
                    </option>
                  ) : (
                    <option selected hidden disabled>
                      Select your City
                    </option>
                  )}
                  {Cities.map((city) => (
                    <option
                      key={`${city}`}
                      value={city}
                      style={{ width: "100%" }}
                    >
                      {city}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          <div className="location-footer">
            {!isAdded ? (
              <button className="location-add" onClick={postLocation}>
                Add
              </button>
            ) : (
              <div className="location-update-delete">
                <button className="location-update" onClick={updateLocation}>
                  Update
                </button>
                <button className="location-delete" onClick={deleteLocation}>
                  Delete
                </button>
              </div>
            )}
          </div>
          {successMsg && <div className="success-message">{successMsg}</div>}
        </div>
      )}
    </Popup>
  );
}
