import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cookies from 'js-cookie'
import DefaultProfile from "./Pages/DefaultProfile/DefaultProfile";
import MainLogin from "./Pages/MainLogin/MainLogin";
import GetStarted from "./Pages/GetStarted/GetStarted";
import LoginUser from "./Pages/LoginUser/LoginUser";
import SetBio from "./Pages/SetBio/SetBio";
import SetDemo from "./Pages/SetDemo/SetDemo";
import SetTheme from "./Pages/SetTheme/SetTheme";
import "./App.css";
// import PrivateRoutes from "./PrivateRouter";

function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<MainLogin/>}> </Route>
          <Route path="/profile" element={<DefaultProfile token = {Cookies.get('token')} id = {Cookies.get('id')} userName = {Cookies.remove('userName')}/>} > </Route>
           {/* <Route element = {<PrivateRoutes/>}>  */}
            <Route path="/getstart" element={<GetStarted token = {Cookies.get('token')} id = {Cookies.get('id')} userName = {Cookies.get('userName')}/>} > </Route>   
            <Route path="/uname" element={<LoginUser token = {Cookies.get('token')} id = {Cookies.get('id')} userName = {Cookies.get('userName')}/>} > </Route>
            <Route path="/bio" element={<SetBio token = {Cookies.get('token')} id = {Cookies.get('id')}/>} > </Route>
            <Route path="/demo" element={<SetDemo token = {Cookies.get('token')} id = {Cookies.get('id')}/>} > </Route>
            <Route path="/theme" element={<SetTheme token = {Cookies.get('token')} id = {Cookies.get('id')}/>} > </Route>
           {/* </Route>  */}
        </Routes>
      </Router>
    </div>
  );
}
export default App;
