import React, { useState, useRef, useEffect } from "react";
import Popup from "reactjs-popup";
import CloseButton from "react-bootstrap/CloseButton";
import { FaUserCircle } from "react-icons/fa";
import Notifiacation from "../../Assets/Notifications.svg";
import HomeLogo from "../../Components/HomeLogo/HomeLogo";
import HomeOptions from "../HomeOptions/HomeOptions";
import "./Notification.css";

function Notification() {
  const [modalOptions, setModalOptions] = useState(false);
  const [modalSettings, setModalSettings] = useState(false);
  const modalOptionsRef = useRef(null);
  const modalSettingsRef = useRef(null);

  // Close modal when clicked outside
  const handleClickOutside = (e) => {
    if (
      (modalOptionsRef.current &&
        !modalOptionsRef.current.contains(e.target)) ||
      (modalSettingsRef.current && !modalSettingsRef.current.contains(e.target))
    ) {
      setModalOptions(false);
      setModalSettings(false);
    }
  };

  //prevent close modal when click inside
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Popup
      trigger={
        <div className="notification">
          <button className="notification-icon">
            <img
              src={Notifiacation}
              alt="Notification-icon"
              className="notification-icon"
            />
          </button>
        </div>
      }
      modal
      nested
    >
      {(close) => (
        <div className="notification-popup">
          <div className="notification-menu-bar">
            <div className="nmb-logo">
              <HomeLogo />
            </div>
            <div className="nmb-title">Notifications</div>
            <HomeOptions />
          </div>
          <div className="notification-header">
            <div className="notification-title">
              <h> Notifications </h>
            </div>

            <div className="notification-close">
              <button
                className="notification-close-btn"
                onClick={() => close()}
              >
                <CloseButton style={{ height: "5px", width: "5px" }} />
              </button>
            </div>
          </div>
          <div className="notification-body">
            <div className="notification-no">
              <div className="notification-user">
                <FaUserCircle className="user-logo" />
              </div>
              <div className="notification-user-details">
                <div className="notification-user-name">
                  <h>James Anderson</h>
                </div>
                <div className="notification-user-occupation">
                  Lawyer, Colombo Sri Lanka
                </div>
                <div className="notification-btns">
                  <button className="notification-confirm">Confirm</button>
                  <button className="notification-delete">Delete</button>
                </div>
              </div>
            </div>
            <div className="notification-no">
              <div className="notification-user">
                <FaUserCircle className="user-logo" />
              </div>
              <div className="notification-user-details">
                <div className="notification-user-name">
                  <h>James Anderson</h>
                </div>
                <div className="notification-user-occupation">
                  Lawyer, Colombo Sri Lanka
                </div>
                <div className="notification-btns">
                  <button className="notification-confirm">Confirm</button>
                  <button className="notification-delete">Delete</button>
                </div>
              </div>
            </div>
            <div className="notification-no">
              <div className="notification-user">
                <FaUserCircle className="user-logo" />
              </div>
              <div className="notification-user-details">
                <div className="notification-user-name">
                  <h>James Anderson</h>
                </div>
                <div className="notification-user-occupation">
                  Lawyer, Colombo Sri Lanka
                </div>
                <div className="notification-btns">
                  <button className="notification-confirm">Confirm</button>
                  <button className="notification-delete">Delete</button>
                </div>
              </div>
            </div>
            <div className="notification-no">
              <div className="notification-user">
                <FaUserCircle className="user-logo" />
              </div>
              <div className="notification-user-details">
                <div className="notification-user-name">
                  <h>James Anderson</h>
                </div>
                <div className="notification-user-occupation">
                  Lawyer, Colombo Sri Lanka
                </div>
                <div className="notification-btns">
                  <button className="notification-confirm">Confirm</button>
                  <button className="notification-delete">Delete</button>
                </div>
              </div>
            </div>
            <div className="notification-no">
              <div className="notification-user">
                <FaUserCircle className="user-logo" />
              </div>
              <div className="notification-user-details">
                <div className="notification-user-name">
                  <h>James Anderson</h>
                </div>
                <div className="notification-user-occupation">
                  Lawyer, Colombo Sri Lanka
                </div>
                <div className="notification-btns">
                  <button className="notification-confirm">Confirm</button>
                  <button className="notification-delete">Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default Notification;
