import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import Popup from "reactjs-popup";
import Form from "react-bootstrap/Form";
import Cookies from "js-cookie";
import CloseButton from "react-bootstrap/CloseButton";
import Work from "../../Assets/Work.svg";
import API_DOMAIN from "../../ApiConfig";
import "./WorkPlace.css";

export default function WorkPlace({ id }) {
  const [work_place, setWorkPlace] = useState("");
  const [workPlaceAdded, setWorkPlaceAdded] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  id = Cookies.get("id");

  //fetch work place
  useEffect(() => {
    const fetchWorkPlace = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/Work/view?id=${id}`);
        console.log(response);
        if (!response.ok) {
          throw new Error("Failed to fetch work place");
        }
        const token = await response.json();
        if (token.success) {
          const { work_place } = jwtDecode(token.token);
          console.log(work_place);
          setWorkPlace(work_place);
          setWorkPlaceAdded(true);
        } else {
          console.error("Failed to fetch work place:", token.error);
        }
      } catch (error) {
        console.error("Failed to fetch work place:", error);
      }
    };
    fetchWorkPlace();
  }, [id]);

  //post work place
  const postWorkPlace = async () => {
    try {
      await fetch(`${API_DOMAIN}/Work`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ work_place, id }),
      });
      console.log("Work Place is added successfully:", { work_place, id });
      setWorkPlaceAdded(true);
      setTimeout(() => {
        setSuccessMsg(true);
      }, 5000);
      setSuccessMsg("Work Place is added successfully");
      window.location.href = "./profile";
    } catch (error) {
      console.error("Failed to post work place:", error);
    }
  };

  //update work place
  const updateWorkPlace = async () => {
    try {
      await fetch(`${API_DOMAIN}/Work/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ work_place, id }),
      });
      console.log("Work Place is updated successfully: ", { work_place, id });
      setTimeout(() => {
        setSuccessMsg(true);
      }, 5000);
      setSuccessMsg("Work Place is updated successfully");
      window.location.href = "./profile";
    } catch (error) {
      console.error("Failed to update work place:", error);
    }
  };

  //delete work place
  const deleteWorkPlace = async () => {
    try {
      await fetch(`${API_DOMAIN}/Work/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      console.log("Work Place is deleted successfully");
      setWorkPlaceAdded(false);
      setTimeout(() => {
        setSuccessMsg(true);
      }, 5000);
      setSuccessMsg("Work Place is deleted successfully");
      window.location.href = "./profile";
    } catch (error) {
      console.error("Failed to delete work place:", error);
    }
  };

  return (
    <Popup
      trigger={
        <button className="demographic">
          <img src={Work} alt="" className="demo-icon" />
          <span className="demo-title">
            {workPlaceAdded
              ? `${work_place.slice(0, 20)}${
                  work_place.length > 20 ? "..." : ""
                }`
              : "Work Place"}
          </span>
        </button>
      }
      modal
      nested
    >
      {(close) => (
        <div className="wp-popup">
          <div className="wp-popup-header">
            <div className="wp-box">
              <div className="wp-close">
                <button className="wp-close-btn" onClick={() => close()}>
                  <CloseButton style={{ height: "5px", width: "5px" }} />
                </button>
              </div>
              <div className="wp-title">
                <h> Work Place </h>
              </div>
            </div>
          </div>
          <div className="wp-body">
            <div className="wp-name">
              <Form.Control
                type="text"
                placeholder="Work Place"
                value={work_place}
                onChange={(e) => setWorkPlace(e.target.value)}
              />
            </div>
          </div>
          <div className="wp-footer">
            {!workPlaceAdded ? (
              <button className="wp-add" onClick={postWorkPlace}>
                Add
              </button>
            ) : (
              <div className="wp-update-delete">
                <button className="wp-update" onClick={updateWorkPlace}>
                  Update
                </button>
                <button className="wp-delete" onClick={deleteWorkPlace}>
                  Delete
                </button>
              </div>
            )}
          </div>
          {successMsg && <div className="success-message">{successMsg}</div>}
        </div>
      )}
    </Popup>
  );
}
