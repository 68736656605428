import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { AiFillHeart } from "react-icons/ai";
import TinlyLogo from "../../Assets/TinlyLogo.png";
import mavicLogo from "../../Assets/MavicLogo.png";
import API_DOMAIN from "../../ApiConfig";
import "./MainLogin.css";

function MainLogin() {
  const handleSignUpWithMavicsoft = () => {
    // Redirect to Mavicsoft's sign-up page
    window.location.href =
      "https://one.mavicsoft.com/?identity=d4828aeaeec4bba86f327245b94c381f5e2b73e68b89ef3f6fba147c2c144e02";
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const userName = queryParams.get("userName");

  if (token && userName) {
    fetch(`${API_DOMAIN}/login/fetch-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, userName }),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch the particular profile");
      }
      return response.json();
    })
    .then((data) => {
      console.log("Response from API:", data);
      if (data.error === "Username already exists") {
        window.location.href = "/profile";
      } else {
        console.log("Token and username sent to API:", userName, token);
        window.location.href = "/getstart";
      }
    })
      .catch((error) => {
        console.error("Failed to fetch profile:", error);
      });
  } else {
    console.error("Failed to redirect");
  }

  Cookies.set("userName", userName);

  useEffect(() => {
    const fetchtoken = async () => {
      try {
        const response = await fetch(
          `${API_DOMAIN}/login/detail/${userName}`
        );
        console.log(response);
        if (!response.ok) {
          throw new Error("Failed to fetch token and id");
        }
        const data = await response.json();
        console.log(data);
        const token = data.token;
        const id = data.userid;

        Cookies.set("token", token);
        Cookies.set("id", id);
      } catch (error) {
        console.error("Failed to fetch token and id: ", error);
      }
    };
    fetchtoken();
  });

  return (
    <div className="login-wrapper">
      <div className="container d-flex justify-content-center align-items-center ">
        <div className="top-section">
          <div className="logo">
            <img src={TinlyLogo} alt="logo" />
          </div>
          <div className="mid-section">
            <p className="p1">Beyond Bio </p>
            <p className="p2">Empower Your Digital Persona! </p>
            <p className="p3"> Tinly</p>
          </div>
          <div className="ml-btn">
            <button className="btn" onClick={handleSignUpWithMavicsoft}>
              <img src={mavicLogo} className="btn-img" alt="mavicLogo" />
              <span>Sign in with Mavic One</span>
            </button>
          </div>
        </div>
        <div className="bottom-section">
        <p>
            Made &nbsp;
            <AiFillHeart className="heart-icon" /> &nbsp; with Tinly
          </p>
        </div>
      </div>
    </div>
  );
}
export default MainLogin;
