import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import CloseButton from "react-bootstrap/CloseButton";
import { IoMdAdd } from "react-icons/io";
import { BsYoutube, BsInstagram, BsSnapchat } from "react-icons/bs";
import {
  BiLogoFacebook,
  BiLogoTiktok,
  BiLogoPatreon,
  BiLogoLinkedin,
} from "react-icons/bi";
import { FaPinterestP } from "react-icons/fa";
import { FaTumblr } from "react-icons/fa6";
import { SlSocialVkontakte } from "react-icons/sl";
import { BsTwitterX } from "react-icons/bs";
import { TbFileUpload } from "react-icons/tb";
import "react-image-crop/dist/ReactCrop.css";
import "./SMLinks.css";
import API_DOMAIN from "../../ApiConfig";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export default function SMLinks() {
  const fileInputRef = useRef(null);
  const [facebookData, setFacebookData] = useState("");
  const [instagramData, setInstagramData] = useState("");
  const [twitterData, setTwitterData] = useState("");
  const [youtubeData, setYoutubeData] = useState("");
  const [tiktokData, setTiktokData] = useState("");
  const [pinterestData, setPinterestData] = useState("");
  const [patreonData, setPatreonData] = useState("");
  const [tumblrData, settumblrData] = useState("");
  const [vkData, setvkData] = useState("");
  const [linkedInData, setLinkedInData] = useState("");
  const [snapchatData, setSnapchatData] = useState("");
  const [addType, setAddType] = useState("");
  const [footerVisible, setFooterVisible] = useState(false);
  const [image, setImage] = useState("");
  const [, setSMAdded] = useState(false);
  const [customLinks, setCustomLinks] = useState("");
  const [validCustomLinks, setValidCustomLinks] = useState(false);
  const [customLinkError, setCustomLinkError] = useState(false);
  const [customLinkTitle, setCustomLinkTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [, setCustomLinkAdded] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const [checkChecked, setCheckChecked] = useState({
    facebook: false,
    instagram: false,
    twitter: false,
    youtube: false,
    tiktok: false,
    pinterest: false,
    patreon: false,
    tumblr: false,
    vk: false,
    linkedin: false,
    snapchat: false,
  });

  const handleCustomLinks = (e) => {
    const enteredCustomLink = e.target.value;
    const UrlCheck =
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
    setCustomLinks(enteredCustomLink);
    if (enteredCustomLink.trim() !== " ") {
      setValidCustomLinks(UrlCheck.test(enteredCustomLink));
      setCustomLinkError("Enter a valid URL (https:// or http://)");
    } else {
      setValidCustomLinks(true);
    }
  };

  const handleCheckBoxChecked = (type, event) => {
    const isChecked = event.target.checked;
    setCheckChecked((prevState) => ({
      ...prevState,
      [type]: isChecked,
    }));
  };

  const id = Cookies.get("id");

  useEffect(() => {
    const fetchSMLinks = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/socialmedia/view/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch social media links");
        }
        const token = await response.json();

        if (token.success) {
          console.log(token);
          const { socialMediaLinks } = jwtDecode(token.token);
          console.log("Social Media Links:", socialMediaLinks);

          socialMediaLinks.forEach((link) => {
            const { type, sm_username } = link;
            if (sm_username !== "N/A") {
              switch (type) {
                case "Facebook":
                  setFacebookData(sm_username);
                  checkChecked.facebook = true;
                  break;
                case "Instagram":
                  setInstagramData(sm_username);
                  checkChecked.instagram = true;
                  break;
                case "X":
                  setTwitterData(sm_username);
                  checkChecked.twitter = true;
                  break;
                case "Youtube":
                  setYoutubeData(sm_username);
                  checkChecked.youtube = true;
                  break;
                case "TikTok":
                  setTiktokData(sm_username);
                  checkChecked.tiktok = true;
                  break;
                case "Pinterest":
                  setPinterestData(sm_username);
                  checkChecked.pinterest = true;
                  break;
                case "Patreon":
                  setPatreonData(sm_username);
                  checkChecked.patreon = true;
                  break;
                case "Tumblr":
                  settumblrData(sm_username);
                  checkChecked.tumblr = true;
                  break;
                case "VK":
                  setvkData(sm_username);
                  checkChecked.vk = true;
                  break;
                case "LinkedIn":
                  setLinkedInData(sm_username);
                  checkChecked.linkedin = true;
                  break;
                case "Snapchat":
                  setSnapchatData(sm_username);
                  checkChecked.snapchat = true;
                  break;
                default:
                  break;
              }
              setSMAdded(true);
            }
          });
        }
      } catch (error) {
        console.error("Failed to fetch social media links:", error);
      }
    };
    fetchSMLinks();
  }, [id]);

  //update social media links
  const updateSMLinks = async () => {
    try {
      const updatedSocialMediaData = [
        { type: "Facebook", username: facebookData },
        { type: "Instagram", username: instagramData },
        { type: "X", username: twitterData },
        { type: "Youtube", username: youtubeData },
        { type: "TikTok", username: tiktokData },
        { type: "Pinterest", username: pinterestData },
        { type: "Patreon", username: patreonData },
        { type: "Tumblr", username: tumblrData },
        { type: "VK", username: vkData },
        { type: "LinkedIn", username: linkedInData },
        { type: "Snapchat", username: snapchatData },
      ];

      const filledUpdatedSocialMediaData = updatedSocialMediaData.filter(
        (entry) => entry.username !== ""
      );

      await fetch(`${API_DOMAIN}/socialmedia/update/${id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          updates: filledUpdatedSocialMediaData,
        }),
      });

      console.log("Social Media Link is updated successfully: ", [
        { filledUpdatedSocialMediaData },
      ]);
      console.log(filledUpdatedSocialMediaData);

      setSMAdded(true);
    } catch (error) {
      console.error("Failed to update custom links:", error);
    }
  };

  //post social media links
  const postSMLinks = async () => {
    try {
      const socialMediaData = [
        { type: "Facebook", username: facebookData },
        { type: "Instagram", username: instagramData },
        { type: "X", username: twitterData },
        { type: "Youtube", username: youtubeData },
        { type: "TikTok", username: tiktokData },
        { type: "Pinterest", username: pinterestData },
        { type: "Patreon", username: patreonData },
        { type: "Tumblr", username: tumblrData },
        { type: "VK", username: vkData },
        { type: "LinkedIn", username: linkedInData },
        { type: "Snapchat", username: snapchatData },
      ];

      const filledSocialMediaData = socialMediaData.filter(
        (entry) => entry.username !== ""
      );

      await fetch(`${API_DOMAIN}/socialmedia/save/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          socialMediaLinks: filledSocialMediaData,
        }),
      });

      console.log("Social Media Link is posted successfully: ", [
        { filledSocialMediaData },
      ]);
      console.log(filledSocialMediaData);
      setSMAdded(true);
    } catch (error) {
      console.error("Error in posting social media links:", error);
    }
  };

  //post custom links
  const postCustomLink = async () => {
    try {
      const pk_idd = id;
      const custom_links = customLinks;
      const title = customLinkTitle;
      const image = selectedFile;

      const formData = new FormData();
      formData.append("custom_links", custom_links);
      formData.append("title", title);
      formData.append("pk_idd", pk_idd);
      formData.append("image", image);

      console.log(custom_links, title, pk_idd, image);

      const response = await fetch(`${API_DOMAIN}/customlinks/insert`, {
        method: "POST",
        body: formData,
      });

      // If response is OK but with a specific message indicating URL already exists
      if (response.status === 200) {
        const responseData = await response.json();
        if (responseData.error === "URL is already exist") {
          setTimeout(() => {
            setErrorMsg(true);
          }, 5000);
          setErrorMsg("URL already exists");
        } else {
          console.log(responseData);
          console.log("Successfully posted custom links");
          console.log(customLinks, customLinkTitle, pk_idd, image);
          setCustomLinkAdded(true);
          window.location.href = "/profile";
        }
      }
    } catch (error) {
      console.log("Error in posting custom links:", error);
    }
  };

  const deleteSMLinks = async (type) => {
    try {
      await fetch(`${API_DOMAIN}/socialmedia/delete/${id}/${type}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      });
      console.log("Successfully deleted social media links:", type);
    } catch (error) {
      console.error("Failed to delete social media links:", error);
    }
  };

  const handleAddTypeChange = (icon) => {
    setAddType(icon);
    setFooterVisible(true);
  };

  const handleClick = async() => {
    await postSMLinks();
    await updateSMLinks();
    await postCustomLink();
    if (!checkChecked.facebook) {
      await deleteSMLinks("Facebook");
    }
    else if (!checkChecked.instagram) {
      await deleteSMLinks("Instagram");
    }
    else if (!checkChecked.twitter) {
      await deleteSMLinks("X");
    }
    else if (!checkChecked.youtube) {
      await deleteSMLinks("yOUTUBE");
    }
    else if (!checkChecked.tiktok) {
      await deleteSMLinks("TikTok");
    }
    else if (!checkChecked.pinterest) {
      await deleteSMLinks("Pinterest");
    }
    else if (!checkChecked.patreon) {
      await deleteSMLinks("Patreon");
    }
    else if (!checkChecked.tumblr) {
      await deleteSMLinks("Tumblr");
    }
    else if (!checkChecked.vk) {
      await deleteSMLinks("VK");
    }
    else if (!checkChecked.linkedin) {
      await deleteSMLinks("LinkedIn");
    }
    else if (!checkChecked.snapchat) {
      await deleteSMLinks("Snapchat");
    }
    window.location.href = "/profile";
  };

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(URL.createObjectURL(image));
        setSelectedFile(image);
      };
      reader.readAsDataURL(image);
    }
  };

  const handleChangeImage = (e) => {
    const newImage = e.target.files[0];
    if (newImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(URL.createObjectURL(newImage)); // Update the image preview
        setSelectedFile(newImage); // Update the selected file
      };
      reader.readAsDataURL(newImage);
    }
  };

  const handleRemoveImage = () => {
    setImage(""); // Clear the displayed image
    setSelectedFile(""); // Reset selectedFile state to empty string or null
  };

  return (
    <Popup
      trigger={
        <button className="add-sm">
          <IoMdAdd className="add-sm-icon" />
        </button>
      }
      modal
      nested
    >
      {(close) => (
        <div className="sm-popup">
          <div className="smp-header">
            <div className="smp-close">
              <button className="smp-close-btn" onClick={() => close()}>
                <CloseButton style={{ height: "5px", width: "5px" }} />
              </button>
            </div>
            <div className="smp-title">
              <h> Add Shortly </h>
            </div>
          </div>
          <div className="smp-buttons">
            <label>
              <button
                className={`smp-btn ${
                  addType === "socialMedia" ? "active" : ""
                }`}
                onClick={() => handleAddTypeChange("socialMedia")}
              >
                Social Media Links
              </button>
            </label>

            <label>
              <button
                className={`smp-btn ${addType === "custom" ? "active" : ""}`}
                onClick={() => handleAddTypeChange("custom")}
              >
                Custom Links
              </button>
            </label>
          </div>
          {addType === "socialMedia" && (
            <div className="smp-body">
              <div className="smp-scroll">
                <div className="add-sm-link">
                  <input
                    type="checkbox"
                    className="select-box"
                    checked={checkChecked.facebook}
                    onChange={(event) =>
                      handleCheckBoxChecked("facebook", event)
                    }
                  />
                  <div className="add-sm-box">
                    <div className="icon-box">
                      <div className="sm-icon">
                        <BiLogoFacebook />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="sm-url"
                      placeholder={"Facebook Username or Page ID"}
                      disabled={!checkChecked.facebook}
                      value={facebookData}
                      onChange={(e) => setFacebookData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add-sm-link">
                  <input
                    type="checkbox"
                    className="select-box"
                    checked={checkChecked.instagram}
                    onChange={(event) =>
                      handleCheckBoxChecked("instagram", event)
                    }
                  />
                  <div className="add-sm-box">
                    <div className="icon-box">
                      <div className="sm-icon">
                        <BsInstagram />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="sm-url"
                      placeholder={"Instagram Username"}
                      disabled={!checkChecked.instagram}
                      value={instagramData}
                      onChange={(e) => setInstagramData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add-sm-link">
                  <input
                    type="checkbox"
                    className="select-box"
                    checked={checkChecked.twitter}
                    onChange={(event) =>
                      handleCheckBoxChecked("twitter", event)
                    }
                  />
                  <div className="add-sm-box">
                    <div className="icon-box">
                      <div className="sm-icon">
                        <BsTwitterX />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="sm-url"
                      placeholder={"X Username"}
                      disabled={!checkChecked.twitter}
                      value={twitterData}
                      onChange={(e) => setTwitterData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add-sm-link">
                  <input
                    type="checkbox"
                    className="select-box"
                    checked={checkChecked.youtube}
                    onChange={(event) =>
                      handleCheckBoxChecked("youtube", event)
                    }
                  />
                  <div className="add-sm-box">
                    <div className="icon-box">
                      <div className="sm-icon">
                        <BsYoutube />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="sm-url"
                      placeholder={"Youtube Channel URL"}
                      disabled={!checkChecked.youtube}
                      value={youtubeData}
                      onChange={(e) => setYoutubeData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add-sm-link">
                  <input
                    type="checkbox"
                    className="select-box"
                    checked={checkChecked.tiktok}
                    onChange={(event) => handleCheckBoxChecked("tiktok", event)}
                  />
                  <div className="add-sm-box">
                    <div className="icon-box">
                      <div className="sm-icon">
                        <BiLogoTiktok />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="sm-url"
                      placeholder={"TikTok Username"}
                      disabled={!checkChecked.tiktok}
                      value={tiktokData}
                      onChange={(e) => setTiktokData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add-sm-link">
                  <input
                    type="checkbox"
                    className="select-box"
                    checked={checkChecked.pinterest}
                    onChange={(event) =>
                      handleCheckBoxChecked("pinterest", event)
                    }
                  />
                  <div className="add-sm-box">
                    <div className="icon-box">
                      <div className="sm-icon">
                        <FaPinterestP />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="sm-url"
                      placeholder={"Pinterest Username"}
                      disabled={!checkChecked.pinterest}
                      value={pinterestData}
                      onChange={(e) => setPinterestData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add-sm-link">
                  <input
                    type="checkbox"
                    className="select-box"
                    checked={checkChecked.patreon}
                    onChange={(event) =>
                      handleCheckBoxChecked("patreon", event)
                    }
                  />
                  <div className="add-sm-box">
                    <div className="icon-box">
                      <div className="sm-icon">
                        <BiLogoPatreon />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="sm-url"
                      placeholder={"Patreon Username"}
                      disabled={!checkChecked.patreon}
                      value={patreonData}
                      onChange={(e) => setPatreonData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add-sm-link">
                  <input
                    type="checkbox"
                    className="select-box"
                    checked={checkChecked.tumblr}
                    onChange={(event) => handleCheckBoxChecked("tumblr", event)}
                  />
                  <div className="add-sm-box">
                    <div className="icon-box">
                      <div className="sm-icon">
                        <FaTumblr />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="sm-url"
                      placeholder={"Tumblr Username"}
                      disabled={!checkChecked.tumblr}
                      value={tumblrData}
                      onChange={(e) => settumblrData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add-sm-link">
                  <input
                    type="checkbox"
                    className="select-box"
                    checked={checkChecked.vk}
                    onChange={(event) => handleCheckBoxChecked("vk", event)}
                  />
                  <div className="add-sm-box">
                    <div className="icon-box">
                      <div className="sm-icon">
                        <SlSocialVkontakte />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="sm-url"
                      placeholder={"VK Username or Page ID"}
                      disabled={!checkChecked.vk}
                      value={vkData}
                      onChange={(e) => setvkData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add-sm-link">
                  <input
                    type="checkbox"
                    className="select-box"
                    checked={checkChecked.linkedin}
                    onChange={(event) =>
                      handleCheckBoxChecked("linkedin", event)
                    }
                  />
                  <div className="add-sm-box">
                    <div className="icon-box">
                      <div className="sm-icon">
                        <BiLogoLinkedin />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="sm-url"
                      placeholder={"LinkedIn Profile URL"}
                      disabled={!checkChecked.linkedin}
                      value={linkedInData}
                      onChange={(e) => setLinkedInData(e.target.value)}
                    />
                  </div>
                </div>
                <div className="add-sm-link">
                  <input
                    type="checkbox"
                    className="select-box"
                    checked={checkChecked.snapchat}
                    onChange={(event) =>
                      handleCheckBoxChecked("snapchat", event)
                    }
                  />
                  <div className="add-sm-box">
                    <div className="icon-box">
                      <div className="sm-icon">
                        <BsSnapchat />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="sm-url"
                      placeholder={"Snapchat Username or Page ID"}
                      disabled={!checkChecked.snapchat}
                      value={snapchatData}
                      onChange={(e) => setSnapchatData(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {addType === "custom" && (
            <div className="custom-body">
              <div className="input-sec">
                <label>Website URL</label>
                <input
                  type="text"
                  className="txt-url"
                  placeholder="https://url or http://url"
                  value={customLinks}
                  onChange={handleCustomLinks}
                  required
                />
                {validCustomLinks ? (
                  ""
                ) : (
                  <div className="error-msg-custom-link">{customLinkError}</div>
                )}
                {errorMsg && (
                  <div className="error-msg-custom-link">{errorMsg}</div>
                )}
              </div>

              <div className="input-sec">
                <label>Website Image or Logo</label>
                <div className="add-img-sec">
                  {image ? (
                    <div className="uploaded-img-container">
                      <img
                        src={image}
                        alt="Website Logo"
                        className="uploaded-img"
                      />
                      <div className="btn-group">
                        <button
                          className="btn-change"
                          onClick={() => fileInputRef.current.click()}
                        >
                          <input
                            type="file"
                            accept="image/*"
                            className="input-img"
                            ref={fileInputRef}
                            onChange={handleChangeImage}
                            hidden
                          />
                          Change Image
                        </button>
                        <button
                          className="btn-remove"
                          onClick={handleRemoveImage}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() =>
                        document.querySelector(".input-img").click()
                      }
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="input-img"
                        onChange={handleImageChange}
                        hidden
                      />
                      <div className="upload-area">
                        <TbFileUpload className="upload-icon" />
                        <p className="upload-desc">
                          Upload a picture in BMP, JPG, JPEG or PNG format.
                          <br /> Maximum file size: 100MB
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="input-sec">
                <label>Website Title</label>
                <input
                  type="text"
                  className="txt-url"
                  placeholder="Title"
                  value={customLinkTitle}
                  onChange={(e) => setCustomLinkTitle(e.target.value)}
                  required
                />
              </div>
            </div>
          )}
          {footerVisible && (
            <div className="smp-footer">
              <button className="smp-save" onClick={() => handleClick(close)}>
                Add
              </button>
            </div>
          )}
        </div>
      )}
    </Popup>
  );
}
