import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { IoMdClose } from "react-icons/io";
import { FaGlobeAmericas } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import API_DOMAIN from "../../ApiConfig";
import { IoCheckmark } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import "./Settings.css";

export default function Settings({ closeModalSettings }) {
  const [privacyChecked, setPrivacyChecked] = useState("public");
  const [themeSelected, setThemeSelected] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [template, setTemplate] = useState("");

  const handleModalClick = (e) => {
    e.stopPropagation(); // Prevent closing the modal when clicked inside
  };

  const handlePrivacyChange = (option) => {
    setPrivacyChecked(option);
  };

  const id = Cookies.get("id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/user?id=${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }
        const token = await response.json();
        console.log(token);
        const { firstName, lastName, userName } = jwtDecode(token.token);
        console.log(firstName, lastName, userName);
        setFirstName(firstName);
        setLastName(lastName);
        setUserName(userName);
        console.log(token);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };
    fetchData();
  }, [id]);

  //Update user details
  const updateUserDetails = async () => {
    try {
      await fetch(`${API_DOMAIN}/login/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          first_name: firstName,
          last_name: lastName,
          username: userName,
        }),
      });
      console.log("Username is successfully added to API");
      console.log({
        id,
        first_name: firstName,
        last_name: lastName,
        username: userName,
      });
    } catch (error) {
      console.error("Error in sending Username: ", error);
    }
  };

  //Update theme color
  const updateTheme = async () => {
    try {
      await fetch(`${API_DOMAIN}/theme/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, color: themeSelected }),
      });
      console.log("Theme is successfully added to API");
      console.log({ id, color: themeSelected });
      window.location.href = "/profile";
    } catch (error) {
      console.error("Error in sending theme: ", error);
    }
  };

  //fetch theme
  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/theme/view/?id=${id}`);
        if (!response) {
          throw new Error("Failed to fetch theme");
        }
        const theme = await response.json();
        const { color } = jwtDecode(theme.token);
        setThemeSelected(color);
        console.log(color);
      } catch (error) {
        console.error("failed to fetch theme: ", error);
      }
    };

    fetchTheme();
  }, [id]);

  //Fetch template
  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const resTemplate = await fetch(
          `${API_DOMAIN}/template/view/?id=${id}`
        );
        if (!resTemplate) {
          throw new Error("Failed to fetch template!");
        }
        const templateType = await resTemplate.json();
        const { template } = jwtDecode(templateType.token);
        setTemplate(template);
        console.log(template);
      } catch (error) {
        console.error("Failed to fetch template: ", error);
      }
    };

    fetchTemplate();
  }, [id]);

  //Update Template
  const updateTemplate = async () => {
    try {
      await fetch(`${API_DOMAIN}/template/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, template }),
      });
      console.log("Template is successfully added to Database");
      console.log({ id, template });
    } catch (error) {
      console.error("Error in sending template: ", error);
    }
  };

  return (
    <div className="settings-popup" onClick={handleModalClick}>
      <div className="sp-header">
        <div
          className="sp-close"
          onClick={() => {
            closeModalSettings(false);
          }}
        >
          <IoMdClose />
        </div>
        <div className="sp-title">
          <h>Settings</h>
        </div>
      </div>
      <div className="sp-body">
        <Accordion>
          <Accordion.Item eventKey="0" className="setting-type">
            <Accordion.Header className="setting-title">Theme</Accordion.Header>
            <Accordion.Body>
              <Accordion>
                <Accordion.Item eventKey="0.1" className="setting-type">
                  <Accordion.Header className="setting-title">
                    Colors
                  </Accordion.Header>
                  <Accordion.Body className="choose-color">
                    {themeSelected === "t2" ? (
                      <div
                        className="choose-color-one-selected"
                        onClick={() => setThemeSelected("t2")}
                      ></div>
                    ) : (
                      <div
                        className="choose-color-one"
                        onClick={() => setThemeSelected("t2")}
                      ></div>
                    )}
                    {themeSelected === "t1" ? (
                      <div
                        className="choose-color-two-selected"
                        onClick={() => setThemeSelected("t1")}
                      ></div>
                    ) : (
                      <div
                        className="choose-color-two"
                        onClick={() => setThemeSelected("t1")}
                      ></div>
                    )}
                    {themeSelected === "t3" ? (
                      <div
                        className="choose-color-three-selected"
                        onClick={() => setThemeSelected("t3")}
                      ></div>
                    ) : (
                      <div
                        className="choose-color-three"
                        onClick={() => setThemeSelected("t3")}
                      ></div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0.2" className="setting-type">
                  <Accordion.Header className="setting-title">
                    Templates
                  </Accordion.Header>
                  <Accordion.Body className="choose-template">
                    <div className="template-radio">
                      <input
                        type="radio"
                        name="em-select"
                        className="radio-btn"
                        checked={template !== "border"}
                        onClick={() => setTemplate("fill")}
                      />
                      Fill
                    </div>
                    <div className="template-radio">
                      <input
                        type="radio"
                        name="em-select"
                        className="radio-btn"
                        checked={template !== "fill"}
                        onClick={() => setTemplate("border")}
                      />
                      Border Only
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className="setting-type">
            <Accordion.Header className="setting-title">
              Account
            </Accordion.Header>
            <Accordion.Body className="account-body">
              <div className="s-name-sec">
                <span className="s-name-label">First Name</span>
                <input
                  type="text"
                  className="s-input"
                  placeholder="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="s-name-sec">
                <span className="s-name-label">Last Name</span>
                <input
                  type="text"
                  className="s-input"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="s-name-sec">
                <span className="s-name-label">User Name</span>
                <input
                  type="text"
                  className="s-input"
                  placeholder="User name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className="setting-type">
            <Accordion.Header className="setting-title">
              Insights
            </Accordion.Header>
            <Accordion.Body className="insights-body">
              <div className="insights-title">Wowers!</div>
              <div className="insights-count">5,675</div>
              <div className="insights-desc">
                Your Tinly profile exploded 5,657 views and counting! Build your
                dream community bigger!
              </div>
              <div className="insights-note">
                Free plan is limited to 1 insight only. Upgrade for more
                powerful insights!
              </div>
              <div className="insights-upgrade">
                <button>Upgrade</button>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3" className="setting-type">
            <Accordion.Header className="setting-title">
              Privacy
            </Accordion.Header>
            <Accordion.Body className="privacy-body">
              <div className="privacy-tab">
                <span className="privacy-icon">
                  <FaGlobeAmericas />
                </span>

                <div className="privacy-type">
                  <div className="privacy-type-label">Public</div>
                  <div className="privacy-type-desc">
                    Your profile visible to everyone
                  </div>
                </div>

                <div className="privacy-select">
                  <input
                    type="radio"
                    name="privacyChecked"
                    checked={privacyChecked === "public"}
                    onChange={() => handlePrivacyChange("public")}
                  />
                </div>
              </div>

              <div className="privacy-tab">
                <span className="privacy-icon">
                  <FaLock />
                </span>

                <div className="privacy-type">
                  <div className="privacy-type-label">Only Me</div>
                  <div className="privacy-type-desc">
                    Only you can view your profile
                  </div>
                </div>

                <div className="privacy-select">
                  <input
                    type="radio"
                    name="privacyChecked"
                    checked={privacyChecked === "private"}
                    onChange={() => handlePrivacyChange("private")}
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4" className="setting-type">
            <Accordion.Header className="setting-title">
              Archive
            </Accordion.Header>
            <Accordion.Body>archive</Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5" className="setting-type">
            <Accordion.Header className="setting-title">
              Upgrade
            </Accordion.Header>
            <Accordion.Body className="upgrade-body">
              <div className="plan-canvas-free">
                <div className="pc-top">
                  <div className="plan-name">FREE</div>
                  <div className="plan-price">$0</div>
                  <div className="plan-sub-desc">Free for a lifetime</div>
                </div>

                <button className="pc-middle-btn">Current Plan</button>

                <div className="pc-content">
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">
                      Unlimited profile visits
                    </p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">Unlimited fan base</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">25 short Links</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">5 Circles</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">3 Profile themes</p>
                  </div>
                  <div className="plan-features">
                    <RxCross2 className="plan-features-icon" />
                    <p className="plan-features-desc">Private profile</p>
                  </div>
                  <div className="plan-features">
                    <RxCross2 className="plan-features-icon" />
                    <p className="plan-features-desc">Profile verification</p>
                  </div>
                  <div className="plan-features">
                    <RxCross2 className="plan-features-icon" />
                    <p className="plan-features-desc">Image Background</p>
                  </div>
                </div>

                <div className="pc-footer">See more...</div>
              </div>

              <div className="plan-canvas-silver">
                <div className="pc-top">
                  <div className="plan-name">SILVER</div>
                  <div className="plan-price">$9.99</div>
                  <div className="plan-sub-desc">Per year</div>
                </div>

                <button className="pc-middle-btn">Get Silver Plan</button>

                <div className="pc-content">
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">
                      Unlimited profile visits
                    </p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">Unlimited fan base</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">50 short Links</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">10 Circles</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">15 Profile themes</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">Private profile</p>
                  </div>
                  <div className="plan-features">
                    <RxCross2 className="plan-features-icon" />
                    <p className="plan-features-desc">Profile verification</p>
                  </div>
                  <div className="plan-features">
                    <RxCross2 className="plan-features-icon" />
                    <p className="plan-features-desc">Image Background</p>
                  </div>
                </div>

                <div className="pc-footer">See more...</div>
              </div>

              <div className="plan-canvas-gold">
                <div className="pc-top">
                  <div className="plan-name">GOLD</div>
                  <div className="plan-price">$29.99</div>
                  <div className="plan-sub-desc">Per year</div>
                </div>

                <button className="pc-middle-btn">Get Gold Plan</button>

                <div className="pc-content">
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">
                      Unlimited profile visits
                    </p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">Unlimited fan base</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">Unlimited short Links</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">20 Circles</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">30 Profile themes</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">Private profile</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">Profile verification</p>
                  </div>
                  <div className="plan-features">
                    <IoCheckmark className="plan-features-icon" />
                    <p className="plan-features-desc">Image Background</p>
                  </div>
                </div>

                <div className="pc-footer">See more...</div>
              </div>

              <div className="compare-plans">
                Compare plans and features{" "}
                <b className="click-plan-compare">here</b>.
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="sp-footer">
        <button
          className="sp-save"
          onClick={() => {
            updateTheme();
            updateUserDetails();
            updateTemplate();
          }}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}
